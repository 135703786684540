import { Dexie } from 'dexie';

export const db = new Dexie('rubika_datapad');

db.version(4).stores({
    user: '++index',
    latest: '++index',
    station: '++index',
});

db.open().catch(function (err) {
    console.error (err.stack || err);
});