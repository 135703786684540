import React, { useState, useEffect, useContext, useRef } from "react";
import { UserContext, Axios } from './UserContext';
import { useIdleTimer } from 'react-idle-timer'
import {ReactComponent as Gear} from '../images/gear.svg';
import {ReactComponent as Icon1} from '../images/icon1.svg';
import {ReactComponent as Icon2} from '../images/icon3.svg';
import { db } from "./Db";
import Carousel, { CarouselItem } from "./Carousel";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export default function Main(){

    // --- Użytkownik i wylogowanie ---

    const {user} = useContext(UserContext);

    const {logout} = useContext(UserContext);

    // Sprawdzanie aktywności

    const [activeJob, setActiveJob] = useState(null);

    const [remaining, setRemaining] = useState(0);

    const [totalWorkTime, setTotalWorkTime] = useState(0);

    const [jobWorkTime, setJobWorkTime] = useState(undefined);

    const [activeStatus, setActiveStatus] = useState(true);

    const [manualStop, setManualStop] = useState(false);

    const latestManualStop = useRef();

    useEffect(() => {

        latestManualStop.current = manualStop;

    }, [manualStop]);

    const [workTimeout, setWorkTimeout] = useState(900000);

    const latestUserId = useRef();

    useEffect(() => {

        if(user.role !== 'none'){

            setTotalWorkTime(user.total_work_time);

        }

        latestUserId.current = user.personal_id;
        
    }, [user]);

    // Manualne zatrzymanie czasu

    function handleWorkTime(){

        if(!activeStatus){

            setActiveStatus(true);

            setManualStop(false);

            setWorkTimeout(900000);

        } else {

            setActiveStatus(false);

            setManualStop(true);

            setWorkTimeout(5000);

        }

    }

    // Zmiana statusu

    const [reactivationMessage, setReactivationMessage] = useState(null);

    function resumeWork(){

        closeModal();

        setManualStop(false);

        setActiveStatus(true);

        setWorkTimeout(900000);

    }

    const onIdle = () => {

        setActiveStatus(false);

    }

    const onActive = () => {

        if(!manualStop){

            setActiveStatus(true);

        } else {

            setModal({...modal, show: true, info: true});

            setReactivationMessage('Czas został zatrzymany. Potwierdź wznowienie pracy poniższym przyciskiem.');

        }

    }

    const onAction = () => {
        //
    }

    // Kontrola aktywności

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: workTimeout,
        throttle: 500
    });

    useEffect(() => {

        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }

    });

    useEffect(() => {

        if(document.hidden){

            setSiteActiveStatus(false);

        } else {

            setSiteActiveStatus(true);

        }

        function handleVisibilityChange(){

            if(document.hidden){

              setSiteActiveStatus(false);

            } else {

              setSiteActiveStatus(true);

            }

        }

        document.addEventListener("visibilitychange", handleVisibilityChange, false);
        
    }, []);

    // Logowanie czasu pracy

    useEffect(() => {

        const h = setInterval(() => {

            if(latestActiveStatus.current && latestSiteActiveStatus.current && latestDifference.current === 0){

                updateWorkTimes('standard', 5);

            }

        }, 5000);

        return () => { clearInterval(h) }
        // eslint-disable-next-line
    }, []);

    //const [upd, setUpd] = useState([]);

    function updateWorkTimes(type, time){

        let plus_value;

        if(type === 'standard'){

            plus_value = time;

        } else if(type === 'update'){

            if(time > 900){

                plus_value = 900;

            } else {

                plus_value = time;

            }

        }

        /*

        //console.log('updating +'+plus_value);

        setUpd((prevArray) => {

            return [...prevArray, plus_value];
            
        });

        */

        let new_total_value = latestTotalWorkTime.current + plus_value;

        setTotalWorkTime(new_total_value);

        if(latestActiveJob.current && latestJobWorkTime.current !== undefined){

            let new_job_value = latestJobWorkTime.current + plus_value;

            setJobWorkTime(new_job_value);

        }

    }

    // Formatowanie czasu

    function formatTimeString(seconds){

        let hours = Math.floor(seconds / 3600);

        let minutes = Math.floor((seconds % 3600) / 60);

        let hoursString = hours < 100 ? ('0' + hours).slice(-2) : ('00' + hours).slice(-3);

        let minutesString = ('0' + minutes).slice(-2);

        let timeString = hoursString + ':' + minutesString;

        return timeString;

    }

    // Aktualny stan strony - aktywna/nieaktywna

    const [siteActiveStatus, setSiteActiveStatus] = useState(null);

    const latestSiteActiveStatus = useRef();

    useEffect(() => {

        if(!latestManualStop.current){

            // Nieaktywny -> aktywny

            if(!latestSiteActiveStatus.current && siteActiveStatus){

                let previousDate = latestSiteActiveDate.current;

                setSiteActiveDate(null);

                let currentDate = new Date();

                let previousDifference = latestDifference.current;

                if(previousDate){

                    let time1 = previousDate.getTime();

                    let time2 = currentDate.getTime();

                    let difference = (time2 - time1)/1000;

                    const seconds = Math.round(difference);

                    if(seconds > previousDifference){

                        setDifference(seconds);

                        updateWorkTimes('update', seconds);

                    }

                }

            }

            // Aktywny -> nieaktywny

            if(latestSiteActiveStatus.current && !siteActiveStatus){

                setSiteActiveDate(new Date());

            }

        }

        latestSiteActiveStatus.current = siteActiveStatus;

        console.log(latestSiteActiveStatus.current);

        // eslint-disable-next-line
    }, [siteActiveStatus]);

    // Data ostatniej aktywności

    const [siteActiveDate, setSiteActiveDate] = useState(null);

    const latestSiteActiveDate = useRef();

    useEffect(() => {

        latestSiteActiveDate.current = siteActiveDate;

    }, [siteActiveDate]);

    // Różnica - czas nieaktywnej strony

    const [difference, setDifference] = useState(0);

    const latestDifference = useRef();

    useEffect(() => {

        ////////////////////////

        //console.log(difference);

        ////////////////////////

        latestDifference.current = difference;

    }, [difference]);

    // Aktualny stan pracuje/nie pracuje

    const latestActiveStatus = useRef();

    useEffect(() => {

        latestActiveStatus.current = activeStatus;

    }, [activeStatus]);

    // Całkowity czas pracy

    const [formatedTotalTime, setFormatedTotalTime] = useState(null);

    const latestTotalWorkTime = useRef();

    useEffect(() => {

        latestTotalWorkTime.current = totalWorkTime;

        const formatedTime = formatTimeString(totalWorkTime);

        setFormatedTotalTime(formatedTime);

    }, [totalWorkTime]);

    // Czas pracy w zadaniu

    const [formatedJobTime, setFormatedJobTime] = useState(null);

    const latestJobWorkTime = useRef();

    useEffect(() => {

        latestJobWorkTime.current = jobWorkTime;

        const formatedTime = formatTimeString(jobWorkTime);

        setFormatedJobTime(formatedTime);

    }, [jobWorkTime]);

    // Aktualnie wybrane zadanie

    const latestActiveJob = useRef();

    useEffect(() => {

        latestActiveJob.current = activeJob;

        if(activeJob){

            setJobWorkTime(activeJob.work_time);

        }

    }, [activeJob]);

    // Aktualizowanie czasu pracy w bazie danych

    useEffect(() => {

        const h = setInterval(() => {

            if((latestActiveStatus.current && latestSiteActiveStatus.current) || latestDifference.current > 0){

                if(latestDifference.current > 0){

                    setDifference(0);

                }

                let personal_id = latestUserId.current;

                let total = latestTotalWorkTime.current;

                let job;

                let job_number;

                if(latestActiveJob.current && latestJobWorkTime.current !== undefined){

                    job = latestJobWorkTime.current;

                    job_number = latestActiveJob.current.job_number;

                }

                let request_type = 'update time';

                Axios.post('classes/measurements.php', { personal_id, total, job, job_number, request_type }, { timeout: 5000 }).then(function(response){

                    if(response.data === true){

                        setTimeUpdateErrorCount(0);

                    } else if(response.data === false){

                        showTimeUpdateError();

                    } else if(typeof response.data === 'object'){

                        let total = response.data.total ? response.data.total : null;

                        let job = response.data.job ? response.data.job : null;

                        if(total){

                            setTotalWorkTime(total);

                        }
                        
                        if(latestActiveJob.current && latestJobWorkTime.current !== undefined && job){

                            setJobWorkTime(job);

                        }

                    }

                }).catch((error) => {

                    showTimeUpdateError();

                    console.warn(error);

                });

            }

        }, 10000);

        return () => { clearInterval(h) }

    }, []);

    const [timeUpdateErrorCount, setTimeUpdateErrorCount] = useState(0);

    const latestTimeUpdateErrorCount = useRef();

    useEffect(() => {

        latestTimeUpdateErrorCount.current = timeUpdateErrorCount;

    }, [timeUpdateErrorCount]);

    function showTimeUpdateError(){

        let error_count = latestTimeUpdateErrorCount.current;

        if(error_count > 1){

            const current_modal = latestModal.current;

            current_modal.show = true;

            current_modal.error = true;

            setModalErrorMessage('Wystąpił problem z aktualizacją czasu pracy. Odśwież stronę, a jeśli problem będzie się powtarzał skontaktuj się z koordynatorem projektu.')

            setModal(current_modal);

        } else {

            setTimeUpdateErrorCount(error_count+1);

        }
        
    }

    // Przełącznik menu

    const [appLayer, setAppLayer] = useState(100);

    // Wyszukiwanie

    const [searching, setSearching] = useState(true);

    // Dane połączone

    const [jobsWithTrains, setJobsWithTrains] = useState([]);

    // Zadania

    const [jobs, setJobs] = useState([]);

    // Pociągi

    const [trains, setTrains] = useState([]);

    // Nazwy pociągów

    const [trainNumbers, setTrainNumbers] = useState([]);

    // Stacje

    const [stations, setStations] = useState([]);

    // Opóźnienia

    const [delays, setDelays] = useState([]);

    // Kamery

    const [cameras, setCameras] = useState([]);

    // Nagrania

    const [recordings, setRecordings] = useState([]);

    // FTP

    const [ftp, setFtp] = useState([]);

    // Pobieranie danych

    useEffect(() => {
        getData();
        console.log(remaining);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getData(){

        let personal_id = user.personal_id;

        let request_type = 'get data';

        setSearching(true);

        Axios.post('classes/data.php', { personal_id, request_type }, { timeout: 30000 }).then(function(response){
            if(typeof response.data === 'object' || Array.isArray(response.data)){
                if(Array.isArray(response.data.jobs_trains)){
                    setJobsWithTrains(response.data.jobs_trains);
                    const jobs_array = response.data.jobs_trains.map(obj => obj['job']);
                    setJobs(jobs_array);
                }
                if(Array.isArray(response.data.delays)){
                    setDelays(response.data.delays);
                }
                if(Array.isArray(response.data.stations)){
                    setStations(response.data.stations);
                }
                if(Array.isArray(response.data.train_numbers)){
                    setTrainNumbers(response.data.train_numbers);
                }
                if(Array.isArray(response.data.cameras)){
                    setCameras(response.data.cameras);
                }
                if(Array.isArray(response.data.recordings)){
                    setRecordings(response.data.recordings);
                }
                if(Array.isArray(response.data.ftp)){
                    setFtp(response.data.ftp);
                }
            } else if (response.data === 0){
                //
            } else {
                setModal({...modal, show: true, info: true});
                setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z koordynatorem projektu.');
            }
            setSearching(false);
        }).catch((error) => {
            console.warn(error);
            setSearching(false);
            setModal({...modal, show: true, info: true});
            setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z koordynatorem projektu.');
        });

    }

    // Wyodrębnianie unikatowych stacji i dat zadań

    const [jobDates, setJobDates] = useState([]);

    const [jobStations, setJobStations] = useState([]);

    function formatDate(dateString){

        const [year, month, day] = dateString.split("-");

        return `${day}/${month}/${year}`;

    }

    useEffect(() => {

        if(jobs.length > 0){ 

            setJobDates(jobs.reduce((unique, obj) => {
                const formattedDate = formatDate(obj.recording_date);
                if (!unique.includes(formattedDate)) {
                  unique.push(formattedDate);
                }
                return unique;
            }, []).sort((a, b) => {
                const [dayA, monthA, yearA] = a.split("/");
                const [dayB, monthB, yearB] = b.split("/");
                const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
                const dateB = new Date(`${yearB}-${monthB}-${dayB}`);
                return dateA - dateB;
            }));

        }
        
    }, [jobs]);

    // Uzupełnianie zadań o nazwy stacji i numery pociągów

    const [updatedJobs, setUpdatedJobs] = useState([]);

    useEffect(() => {

        if(jobs.length > 0 && stations.length > 0 && trainNumbers.length > 0){ 

            const jobsWithStationName = jobs.filter(job => job.station_name);

            const uniqueStationNames = [...new Set(jobsWithStationName.map(job => job.station_name))];

            const sortedUniqueStationNames = uniqueStationNames.sort((a, b) => a.localeCompare(b));

            setJobStations(sortedUniqueStationNames);

            const updated = [...jobs];

            updated.forEach(job => {

                if(job.train_id){

                    const train_search = trainNumbers.find(u => u.train_id === job.train_id);

                    if(train_search){

                        const first_station = stations.find(u => u.station_id === train_search.first_station);

                        const last_station = stations.find(u => u.station_id === train_search.last_station);

                        job.train_number = train_search.train_number;

                        if(first_station){

                            job.first_station_name = first_station.name;

                        }

                        if(last_station){

                            job.last_station_name = last_station.name;

                        }

                    }

                }

            });

            setUpdatedJobs(updated)

        }
        
    }, [jobs, stations, trainNumbers]);

    // Uzupełnianie pociągów o nazwy stacji i numer PKP

    const [updatedTrains, setUpdatedTrains] = useState([]);

    useEffect(() => {

        if(trains.length > 0 && stations.length > 0 && trainNumbers.length > 0){ 

            const updated = [...trains];

            updated.forEach(train => {

                const train_search = trainNumbers.find(u => u.train_id === train.train_id);

                if(train_search){

                    train.train_number = train_search.train_number;

                    const first_station = stations.find(u => u.station_id === train_search.first_station);

                    const last_station = stations.find(u => u.station_id === train_search.last_station);

                    if(first_station){

                        train.first_station_name = first_station.name;

                    }

                    if(last_station){

                        train.last_station_name = last_station.name;

                    }

                }

            });

            setUpdatedTrains(updated);

        }
        
    }, [trains, stations, trainNumbers]);

    // Pobieranie pomiarów

    const [mergedTrains, setMergedTrains] = useState([]);

    useEffect(() => {

        if(updatedTrains.length > 0 && latestActiveJob.current){ 

            if(latestActiveJob.current.station_id){

                const train_ids = updatedTrains.map(train => train.train_id);

                getCompletedMeasurements(train_ids);

            }

            if(updatedTrains[0].stations){

                const stations_ids = updatedTrains[0].stations.map(array => array[0]);

                getCompletedMeasurements(stations_ids);

            }

            function getCompletedMeasurements(data){

                let job_number = latestActiveJob.current.job_number;

                let personal_id = latestActiveJob.current.personal_id;

                let request_type = 'get data';

                let station_id = latestActiveJob.current.station_id;

                let train_id = latestActiveJob.current.train_id;

                Axios.post('classes/measurements.php', { personal_id, job_number, data, station_id, train_id, request_type }, { timeout: 10000 }).then(function(response){
                    if(Array.isArray(response.data.measurements) && Array.isArray(response.data.photos)){
                        mergeTrainData(response.data);

                    } else {
                        
                    }
                    
                }).catch((error) => {
                    console.warn(error);
                    
                });

            }

        }

        function mergeTrainData(data){

            const db_measurements = data.measurements;
    
            const db_photos = data.photos;
    
            let groupedObjects;
    
            let newArrayWithMeasurements;
    
            if(latestActiveJob.current.station_id){
    
                groupedObjects = db_measurements.reduce((acc, obj) => {
                    const { id, train_id } = obj;
                    if (!acc[train_id] || acc[train_id].id < id) {
                        acc[train_id] = obj;
                    }
                    return acc;
                }, {});
    
                const uniqueObjects = Object.values(groupedObjects);
    
                newArrayWithMeasurements = updatedTrains.map(obj => {
    
                    const { train_id } = obj;
        
                    const correspondingObject = uniqueObjects.find(uniqueObj => uniqueObj.train_id === train_id);
        
                    obj.measurement = correspondingObject || null;
        
                    return obj;
        
                });
    
            } else {
    
                groupedObjects = db_measurements.reduce((acc, obj) => {
                    const { id, station_id } = obj;
                    if (!acc[station_id] || acc[station_id].id < id) {
                        acc[station_id] = obj;
                    }
                    return acc;
                }, {});
    
                const uniqueObjects = Object.values(groupedObjects);
    
                newArrayWithMeasurements = updatedTrains;
    
                newArrayWithMeasurements[0].measurement = uniqueObjects;
    
            }
                
            const updated = newArrayWithMeasurements.map(train => {
    
                const trainPhotos = db_photos.filter(photo => photo.train_id === train.train_id);
    
                return { ...train, photos: trainPhotos };
    
            });
    
            setMergedTrains(updated);
            
        };

    }, [updatedTrains]);

    // Filtrowanie dostępnych zadań

    const [filteredJobs, setFilteredJobs] = useState([]);

    const [jobFilters, setJobFilters] = useState({job_station_name: '', job_date: ''});

    // Usuwanie nieaktywnych stacji/dat przy filtrowaniu

    useEffect(() => {

        if(filteredJobs.length > 0){

            setJobDates(filteredJobs.reduce((unique, obj) => {
                const formattedDate = formatDate(obj.recording_date);
                if (!unique.includes(formattedDate)) {
                  unique.push(formattedDate);
                }
                return unique;
            }, []).sort((a, b) => {
                const [dayA, monthA, yearA] = a.split("/");
                const [dayB, monthB, yearB] = b.split("/");
                const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
                const dateB = new Date(`${yearB}-${monthB}-${dayB}`);
                return dateA - dateB;
            }));

            const jobsWithStationName = filteredJobs.filter(job => job.station_name);

            const uniqueStationNames = [...new Set(jobsWithStationName.map(job => job.station_name))];

            const sortedUniqueStationNames = uniqueStationNames.sort((a, b) => a.localeCompare(b));

            setJobStations(sortedUniqueStationNames);

        } else {

            setJobDates([]);

            setJobStations([]);

        }
        
    }, [filteredJobs]);

    useEffect(() => {

        if(updatedJobs.length > 0){

            const filteredData = updatedJobs.filter(item => {
                if(jobFilters.job_station_name === '' && jobFilters.job_date === '') {
                    return true;
                }
                return (
                    (jobFilters.job_station_name === '' || item.station_name === jobFilters.job_station_name) &&
                    (jobFilters.job_date === '' || formatDate(item.recording_date) === jobFilters.job_date)
                );
            });

            filteredData.sort((a, b) => a.job_number - b.job_number);
    
            setFilteredJobs(filteredData);

        }
        
    }, [updatedJobs, jobFilters]);

    function onJobFilterChange(e) {

        const value = e.target.value;

        const name = e.target.name;

        setJobFilters(prevFilters => {
            return {
                ...prevFilters,
                [name]: value
            }
        });

    }

    // FTP

    const [ftpList, setFtpList] = useState([]);

    function copyText(id){

        let ele = document.getElementById(id);
        let text = ele.innerText;
        let tempTextArea = document.createElement('textarea');
        tempTextArea.value = text;
        document.body.appendChild(tempTextArea);   
        tempTextArea.select();
        document.execCommand('copy'); 
        document.body.removeChild(tempTextArea);

        if(id === 'ftp_host'){

            setButtonStatus({ftp_host: true, ftp_user: false, ftp_password: false});

        } else if(id === 'ftp_user'){

            setButtonStatus({ftp_host: false, ftp_user: true, ftp_password: false});

        } else {

            setButtonStatus({ftp_host: false, ftp_user: false, ftp_password: true});

        }

        setTimeout(() => { setButtonStatus({ftp_host: false, ftp_user: false, ftp_password: false}) }, 2000);

    }

    const [buttonStatus, setButtonStatus] = useState({
        ftp_host: false,
        ftp_user: false,
        ftp_password: false
    });

    // Aktywowanie zadania

    function activateJob(job_number){

        const match = jobsWithTrains.find(obj => obj.job.job_number === job_number);

        const station_id = match.job.station_id;

        const rec_date = match.job.recording_date;

        if(station_id){

            match.trains.forEach(train => {

                const found = Object.keys(train).find(key => train[key] === station_id);
    
                const index = found.substring(found.indexOf("_") + 1);
    
                train.station_index = index;

                const delay = delays.filter(item => item.train_id === train.train_id && item.day === rec_date);

                if(delay.length > 0){

                    const exact = delay.filter(item => item.station_id === station_id);

                    if(exact.length > 0){

                        train.delay = 'opóźniony ' + exact[0].delay + ' min.';

                    } else {

                        for(let i=index-1; i>=1 ;i--){

                            let current_index = 'station_'+i;

                            let current_station = train[current_index];

                            const found = delay.filter(item => item.station_id === current_station);

                            if(found.length > 0){

                                let difference = index - i;

                                train.delay = 'opóźniony ' + found[0].delay + ' min. ' + difference + ' st. wcześniej';

                                break;

                            }

                        }

                    }

                } else {

                    train.delay = null;

                }
    
            });
    
            const sortedTrains = match.trains.sort((a, b) => {
    
                const stationIndexA = a.station_index;
                const stationIndexB = b.station_index;
    
                // Extract departure and arrival times using the respective station indexes
                const departureA = a["departure_hour_" + stationIndexA];
                const departureB = b["departure_hour_" + stationIndexB];
                const arrivalA = a["arrival_hour_" + stationIndexA];
                const arrivalB = b["arrival_hour_" + stationIndexB];
    
                const timeA = departureA || arrivalA;

                const timeB = departureB || arrivalB
            
                const dateA = new Date(`1970-01-01 ${timeA}`);

                const dateB = new Date(`1970-01-01 ${timeB}`);
            
                return dateA - dateB;

            });
    
            setTrains(sortedTrains);

            const cameras_list = cameras.filter(item => item.station_id === station_id && item.recording_date === rec_date);

            setCamerasList(cameras_list);

            const ftp_list = ftp.filter(item => item.station_id === station_id && item.recording_date === rec_date);

            setFtpList(ftp_list);

        } else {

            const train = match.trains[0];

            const arr = [];

            for (let i = 1; i <= 60; i++) {

                let current_station = train['station_'+i];

                if(!current_station){

                    break;

                } else {

                    const small = [];

                    window['station_' + i + '_name'] = stations.find(u => u.station_id === current_station);

                    train['station_' + i + '_name'] = window['station_' + i + '_name'].name;

                    small.push(current_station, window['station_' + i + '_name'].name, train['arrival_hour_'+i], train['departure_hour_'+i], train['platform_number_'+i], train['lane_number_'+i]);

                    const delay = delays.filter(item => item.station_id === current_station && item.train_id === train.train_id && item.day === rec_date);

                    if(delay.length > 0){

                        let latest = delay.length -1;

                        small.push(delay[latest].delay);

                    } else {

                        small.push(null);

                    }

                    if(recordings.find(u => u.station_id === current_station && u.recording_date === rec_date)){

                        small.push(true);

                    } else {

                        small.push(false);

                    }

                    arr.push(small);

                }

            }

            train.stations = arr;

            const updatedTrain = [];

            updatedTrain.push(train);

            setTrains(updatedTrain);

        }

        setActiveJob(match.job);

        if(match.work_time){
            setJobWorkTime(match.work_time);
        } else {
            setJobWorkTime(0);
        }

        setAppLayer(200);

    }

    // Wyznaczanie slajdów do podglądu obrazów z kamer

    const [camerasList, setCamerasList] = useState([]);

    const [cameraSlides, setCameraSlides] = useState([]);

    useEffect(() => {

        if(camerasList.length > 0){

            const current_list = [...camerasList];

            current_list.forEach(camera => {

                const url = "/photos/" + camera.station_id + "_" + camera.recording_date + "_" + camera.camera_number + ".jpg";

                camera.url = url;

                camera.name = "Kamera " + camera.camera_number;
                
            });

            const sortedList = current_list.sort((a, b) => {

                return Number(a.camera_number) - Number(b.camera_number);

            });

            if(sortedList.length > 3){

                const station_id = sortedList[0].station_id;

                const recording_date = sortedList[0].recording_date;

                const layout_url = "/photos/layout/" + station_id + "_" + recording_date + ".jpg";

                const new_object = {url: layout_url, name: "Schemat montażu"}

                sortedList.unshift(new_object);

            }

            setCameraSlides(sortedList);

        } else {

            setCameraSlides([]);

        }

    }, [camerasList]);

    const [openModal, setOpenModal] = useState(false);

    const [imageIndex, setImageIndex] = useState();

    const [modalSlides, setModalSlides] = useState([]);

    function runModal(id){
        setImageIndex(id);
        setOpenModal(true);
    }

    useEffect(() => {
        setModalSlides(
            cameraSlides.map((image) => {
                return { src: image.url };
            })
        );
    }, [cameraSlides]);

    function handleMarkerGallery(index){
        runModal(index);
    }

    // Zmiana zadania

    function exitJob(){
        setPreviousLoad(true);
        setLatest({});
        setActiveJob(null);
        setJobWorkTime(undefined);
        setAppLayer(100);
        getData();
        setMergedTrains([]);
        setActiveTrain({});
        setActiveStation(null);
        clearJobFormData();
        setCamerasList([]);
        setFtpList([]);

        db.latest.clear();
    }

    // Aktywowanie pociągu lub stacji do pomiarów

    const [activeTrain, setActiveTrain] = useState({});

    const latestActiveTrain = useRef();

    useEffect(() => {

        latestActiveTrain.current = activeTrain;
        
    }, [activeTrain]);

    const [tempTrainToActivate, setTempTrainToActivate] = useState(null);

    function activateTrain(id){

        const old_measurement = activeTrain.measurement;

        const train_to_activate = mergedTrains.filter(obj => {return obj.id === id});

        const train = train_to_activate[0];

        // Jeśli aktywowany jest ten sam pociąg - koniec funkcji

        if(train.id === activeTrain.id){

            return;

        } else {

            if(old_measurement){

                let prev_entered_sum = old_measurement.entered_sum;

                let prev_exited_sum = old_measurement.exited_sum;

                let prev_time_arrival = old_measurement.arrival_time;

                let prev_time_departure = old_measurement.departure_time;

                if(measurementFormSummary.entered_sum !== prev_entered_sum || measurementFormSummary.exited_sum !== prev_exited_sum || time.arrival !== prev_time_arrival || time.departure !== prev_time_departure){

                    setTempTrainToActivate(train);
    
                    setModal({...modal, show: true, info: true});

                    return;

                } else {

                    activateSelectedTrain(train);

                    return;

                }

            }

            if(measurementFormSummary.entered_sum !== '' || measurementFormSummary.exited_sum !== '' || time.arrival !== '' || time.departure !== ''){

                setTempTrainToActivate(train);

                setModal({...modal, show: true, info: true});

            } else {

                activateSelectedTrain(train)

            }
    
        }

    }

    function confirmStageChange(){

        closeModal();

        if(tempTrainToActivate){
            const new_train = tempTrainToActivate;
            clearJobFormData();
            activateSelectedTrain(new_train);
            setTempTrainToActivate(null);
        }   

        if(tempStationToActivate){
            clearJobFormData();
            activateSelectedStation(tempStationToActivate);
            setTempStationToActivate(null);
        }   

    }

    function activateSelectedTrain(train){

        db.latest.update(1, {train: null, station: null, measurements: null});
        setLatest({});
        setMeasurementFormData({});
        setTime({arrival: "", departure: ""});
        setAccuracy("");
        setAdditionalComment("");

        setActiveTrain(train);

        if(train.measurement){

            setMeasurementSaving(false);

            restoreMeasurements(train.measurement);

        } else {

            setMeasurementSaving(true);

        }

    }

    const [activeStation, setActiveStation] = useState(null);

    const latestActiveStation = useRef();

    useEffect(() => {

        latestActiveStation.current = activeStation;
        
    }, [activeStation]);

    const [tempStationToActivate, setTempStationToActivate] = useState(null);

    function activateStation(station_id){

        const old_measurement = mergedTrains[0].measurement.filter(obj => {return obj.station_id === activeStation});

        // Jeśli aktywowana jest ta sama stacja - koniec funkcji

        if(activeStation === station_id){

            return;

        } else {

            if(old_measurement.length > 0){

                const old = old_measurement[0];

                let prev_entered_sum = old.entered_sum;

                let prev_exited_sum = old.exited_sum;

                let prev_time_arrival = old.arrival_time;

                let prev_time_departure = old.departure_time;

                if(measurementFormSummary.entered_sum !== prev_entered_sum || measurementFormSummary.exited_sum !== prev_exited_sum || time.arrival !== prev_time_arrival || time.departure !== prev_time_departure){

                    setTempStationToActivate(station_id);
    
                    setModal({...modal, show: true, info: true});

                    return;

                } else {

                    activateSelectedStation(station_id);

                    return;

                }

            }

            if(measurementFormSummary.entered_sum !== '' || measurementFormSummary.exited_sum !== '' || time.arrival !== '' || time.departure !== ''){

                setTempStationToActivate(station_id);

                setModal({...modal, show: true, info: true});

            } else {

                activateSelectedStation(station_id);

            }
    
        }

    }

    function activateSelectedStation(station_id){

        db.latest.update(1, {train: null, station: null, measurements: null});
        setLatest({});
        setMeasurementFormData({});
        setTime({arrival: "", departure: ""});
        setAccuracy("");
        setAdditionalComment("");

        setActiveStation(station_id);

        let recording_date = activeJob.recording_date;

        const cameras_list = cameras.filter(item => item.station_id === station_id && item.recording_date === recording_date);

        setCamerasList(cameras_list);

        const ftp_list = ftp.filter(item => item.station_id === station_id && item.recording_date === recording_date);

        setFtpList(ftp_list);

        if(mergedTrains[0].measurement){

            const old_measurement = mergedTrains[0].measurement.filter(obj => {return obj.station_id === station_id});

            if(old_measurement.length > 0){

                setMeasurementSaving(false);

                restoreMeasurements(old_measurement);

            } else {

                setMeasurementSaving(true);

            }

        } else {

            setMeasurementSaving(true);

        }

    }

    // Przywracanie ostatniego pomiaru

    function restoreMeasurements(data){

        if(Array.isArray(data)){
            data = data[0];
        }

        const oldMeasurementFormData = {};

        /*

        if(data.entered_1) oldMeasurementFormData.entered_1 = data.entered_1;

        if(data.entered_2) oldMeasurementFormData.entered_2 = data.entered_2;

        if(data.entered_3) oldMeasurementFormData.entered_3 = data.entered_3;

        if(data.entered_4) oldMeasurementFormData.entered_4 = data.entered_4;

        if(data.entered_5) oldMeasurementFormData.entered_5 = data.entered_5;

        if(data.entered_6) oldMeasurementFormData.entered_6 = data.entered_6;

        if(data.exited_1) oldMeasurementFormData.exited_1 = data.exited_1;

        if(data.exited_2) oldMeasurementFormData.exited_2 = data.exited_2;

        if(data.exited_3) oldMeasurementFormData.exited_3 = data.exited_3;

        if(data.exited_4) oldMeasurementFormData.exited_4 = data.exited_4;

        if(data.exited_5) oldMeasurementFormData.exited_5 = data.exited_5;

        if(data.exited_6) oldMeasurementFormData.exited_6 = data.exited_6;

        */

        if(data.entered_1 !== null && data.entered_1 !== undefined) oldMeasurementFormData.entered_1 = data.entered_1;

        if(data.entered_2 !== null && data.entered_2 !== undefined) oldMeasurementFormData.entered_2 = data.entered_2;

        if(data.entered_3 !== null && data.entered_3 !== undefined) oldMeasurementFormData.entered_3 = data.entered_3;

        if(data.entered_4 !== null && data.entered_4 !== undefined) oldMeasurementFormData.entered_4 = data.entered_4;

        if(data.entered_5 !== null && data.entered_5 !== undefined) oldMeasurementFormData.entered_5 = data.entered_5;

        if(data.entered_6 !== null && data.entered_6 !== undefined) oldMeasurementFormData.entered_6 = data.entered_6;

        if(data.exited_1 !== null && data.exited_1 !== undefined) oldMeasurementFormData.exited_1 = data.exited_1;

        if(data.exited_2 !== null && data.exited_2 !== undefined) oldMeasurementFormData.exited_2 = data.exited_2;

        if(data.exited_3 !== null && data.exited_3 !== undefined) oldMeasurementFormData.exited_3 = data.exited_3;

        if(data.exited_4 !== null && data.exited_4 !== undefined) oldMeasurementFormData.exited_4 = data.exited_4;

        if(data.exited_5 !== null && data.exited_5 !== undefined) oldMeasurementFormData.exited_5 = data.exited_5;

        if(data.exited_6 !== null && data.exited_6 !== undefined) oldMeasurementFormData.exited_6 = data.exited_6;

        setMeasurementFormData(oldMeasurementFormData);

        const old_time = {};

        old_time.arrival = data.arrival_time ? data.arrival_time : "";

        old_time.departure = data.departure_time ? data.departure_time : "";

        setTime(old_time);

        if(data.accuracy) setAccuracy(data.accuracy);

        if(data.comments) setAdditionalComment(data.comments);

    }

    const [measurementFormData, setMeasurementFormData] = useState({});

    function measurementFormChange(event){

        const {name, value} = event.target;

        setMeasurementFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: Number(value)
            }
        });

    }

    const [measurementFormSummary, setMeasurementFormSummary] = useState({entered_sum: "", exited_sum: ""});

    useEffect(() => {

        if(Object.keys(measurementFormData).length > 0){

            const entered_sum = calculateEnteredSum();

            const exited_sum = calculateExitedSum();
    
            function calculateEnteredSum(){
    
                let entered_1 = measurementFormData.entered_1 ? measurementFormData.entered_1 : 0;
                let entered_2 = measurementFormData.entered_2 ? measurementFormData.entered_2 : 0;
                let entered_3 = measurementFormData.entered_3 ? measurementFormData.entered_3 : 0;
                let entered_4 = measurementFormData.entered_4 ? measurementFormData.entered_4 : 0;
                let entered_5 = measurementFormData.entered_5 ? measurementFormData.entered_5 : 0;
                let entered_6 = measurementFormData.entered_6 ? measurementFormData.entered_6 : 0;
    
                let sum = entered_1 + entered_2 + entered_3 + entered_4 + entered_5 + entered_6;

                if(measurementFormData.entered_1 === undefined && measurementFormData.entered_2 === undefined && measurementFormData.entered_3 === undefined && measurementFormData.entered_4 === undefined && measurementFormData.entered_5 === undefined && measurementFormData.entered_6 === undefined){
                    return "";
                } else {
                    return sum;
                }
    
            }

            function calculateExitedSum(){
    
                let exited_1 = measurementFormData.exited_1 ? measurementFormData.exited_1 : 0;
                let exited_2 = measurementFormData.exited_2 ? measurementFormData.exited_2 : 0;
                let exited_3 = measurementFormData.exited_3 ? measurementFormData.exited_3 : 0;
                let exited_4 = measurementFormData.exited_4 ? measurementFormData.exited_4 : 0;
                let exited_5 = measurementFormData.exited_5 ? measurementFormData.exited_5 : 0;
                let exited_6 = measurementFormData.exited_6 ? measurementFormData.exited_6 : 0;
    
                let sum = exited_1 + exited_2 + exited_3 + exited_4 + exited_5 + exited_6;

                //if(!measurementFormData.exited_1 && !measurementFormData.exited_2 && !measurementFormData.exited_3 && !measurementFormData.exited_4 && !measurementFormData.exited_5 && !measurementFormData.exited_6){
                if(measurementFormData.exited_1 === undefined && measurementFormData.exited_2 === undefined && measurementFormData.exited_3 === undefined && measurementFormData.exited_4 === undefined && measurementFormData.exited_5 === undefined && measurementFormData.exited_6 === undefined){
                    return "";
                } else {
                    return sum;
                }
    
            }
    
            setMeasurementFormSummary({entered_sum: entered_sum, exited_sum: exited_sum});

        } else {

            setMeasurementFormSummary({entered_sum: "", exited_sum: ""});

        }

    }, [measurementFormData]);

    const [time, setTime] = useState({arrival: "", departure: ""});

    const handleTimeChange = (event) => {

        const {name, value} = event.target;

        setTime(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });

    };

    function manualTimeChange(handle, type, field){

        // Deklarowanie nowej wartości

        let new_value;

        // Sprawdzanie aktualnej wartości

        let current = time[field];

        // Jeśli pole jest puste 

        if(current === ''){

            // Jeśli następuje zmiana godzin

            if(type === 'hour'){

                // Jeśli wartość jest zwiększana

                if(handle === 'increment'){

                    new_value = '01:00';

                // Jeśli wartość jest zmniejszana

                } else {

                    new_value = '23:00';

                }

            // Jeśli następuje zmiana minut

            } else {

                // Jeśli wartość jest zwiększana

                if(handle === 'increment'){

                    new_value = '00:01';

                // Jeśli wartość jest zmniejszana

                } else {

                    new_value = '23:59';

                }

            }

        // Jeśli pole ma już wartość

        } else {

            // Wyodrębnianie godzin i minut

            let arr = current.split(":");

            let hours_raw = arr[0];

            let minutes_raw = arr[1];

            let hours = parseInt(hours_raw);

            let minutes = parseInt(minutes_raw);

            if(hours === '' || minutes === ''){

                setTime(prevFormData => {
                    return {
                        ...prevFormData,
                        [field]: ''
                    }
                });

                return;

            }

            if(type === 'hour'){

                // Jeśli wartość jest zwiększana

                if(handle === 'increment'){

                    // Jeśli jest 23 godzina - zmiana na północ

                    if(hours === 23){

                        minutes = minutes < 10 ? "0" + minutes : minutes.toString();

                        new_value = "00:"+minutes;

                    } else {

                        let new_hour = hours+1;

                        new_hour = new_hour < 10 ? "0" + new_hour : new_hour.toString();

                        minutes = minutes < 10 ? "0" + minutes : minutes.toString();

                        new_value = new_hour+":"+minutes;

                    }

                // Jeśli wartość jest zmniejszana

                } else {

                    // Jeśli jest północ - zmiana na 23

                    if(hours === 0){

                        minutes = minutes < 10 ? "0" + minutes : minutes.toString();

                        new_value = "23:"+minutes;

                    } else {

                        let new_hour = hours-1;

                        new_hour = new_hour < 10 ? "0" + new_hour : new_hour.toString();

                        minutes = minutes < 10 ? "0" + minutes : minutes.toString();

                        new_value = new_hour+":"+minutes;

                    }

                }

            // Jeśli następuje zmiana minut

            } else {

                if(handle === 'increment'){

                    // Jeśli jest 59 minuta - zmiana na 00

                    if(minutes === 59){

                        let new_hour;

                        if(hours === 23){
                            new_hour = 0;
                        } else {
                            new_hour = hours+1;
                        }

                        new_hour = new_hour < 10 ? "0" + new_hour : new_hour.toString();

                        new_value = new_hour+":00";

                    } else {

                        hours = hours < 10 ? "0" + hours : hours.toString();

                        let new_minutes = minutes+1;

                        new_minutes = new_minutes < 10 ? "0" + new_minutes : new_minutes.toString();

                        new_value = hours+":"+new_minutes;

                    }

                // Jeśli wartość jest zmniejszana

                } else {

                    // Jeśli jest 00 - zmiana na 59

                    if(minutes === 0){

                        
                        let new_hour;

                        if(hours === 0){
                            new_hour = 23;
                        } else {
                            new_hour = hours-1;
                        }

                        new_hour = new_hour < 10 ? "0" + new_hour : new_hour.toString();

                        new_value = new_hour+":59";

                    } else {

                        hours = hours < 10 ? "0" + hours : hours.toString();

                        let new_minutes = minutes-1;

                        new_minutes = new_minutes < 10 ? "0" + new_minutes : new_minutes.toString();

                        new_value = hours+":"+new_minutes;

                    }

                }

            }

        }

        setTime(prevFormData => {
            return {
                ...prevFormData,
                [field]: new_value
            }
        });

    }

    const [accuracy, setAccuracy] = useState('');

    // Suwak widoczności

    const handleAccuracyChange = (event) => {
        setAccuracy(parseInt(event.target.value));
    };

    const [sliderInfo, setSliderInfo] = useState(false);

    // Modale

    const [modalMessage, setModalMessage] = useState(null);

    const [modalErrorMessage, setModalErrorMessage] = useState(null);

    const [modal, setModal] = useState({
        show: false, 
        add_photo: false,
        show_photo: false,
        cameras_photos: false,
        ftp_info: false,
        info: false,
        time: false,
        error: false
    });

    function closeModal(){

        setModal({
            show: false, 
            add_photo: false,
            show_photo: false,
            cameras_photos: false,
            ftp_info: false,
            info: false,
            time: false,
            error: false
        });

        setUploadedFiles([]);
        setUploadedPreviews([]);
        setActivePreview(null);
        setUploadError(null);

        // Podgląd zdjęć

        setPhotosToShow([]);
        setActivePhotoShow(null);

        // Informacyjny

        setFormErrors([]);
        setModalMessage(null);
        setReactivationMessage(null);

        // Błąd

        setModalErrorMessage(null);

        // Tymczasowe

        setTempTrainToActivate(null);
        setTempStationToActivate(null);

    }

    // Aktualnie wybrane zadanie

    const latestModal = useRef();

    useEffect(() => {

        latestModal.current = modal

    }, [modal]);

    function handleModalClose(){
        if(uploadedFiles.length === 0 && !modal.error){
            closeModal();
        } else if(modal.error && (modal.add_photo || modal.show_photo || modal.info)){
            setModal({...modal, error: false});
            setModalErrorMessage(null);
        } else if(uploadedFiles.length === 0){
            closeModal();
        }
    }

    // Dodawanie zdjęć

    const [uploadedFiles, setUploadedFiles] = useState([]);

    const [uploadError, setUploadError] = useState(null);

    // Wklejanie zdjęć

    const handlePaste = async (e) => {

        setUploadError(null);

        let item = await e.clipboardData.items[0];

        if(item.type.indexOf("image") === 0){

            let file = item.getAsFile();

            const images = [];

            images.push(file)

            setUploadedFiles(images);

        } else {

            setUploadError('Wklejone dane to nie zdjęcie');
            
        }

    }

    // Upuszczanie zdjęć

    function allowDrop(e){
        e.preventDefault();
    }

    function handleDrop(e){

        e.preventDefault();

        setUploadError(null);

        let imageTypes = ['image/png', 'image/gif', 'image/bmp', 'image/jpeg', 'image/jpg'];

        let raw_files = e.dataTransfer.files;

        const files = Array.from(raw_files);

        const images = [];

        files.forEach(file => {
            if(imageTypes.includes(file.type)){
                images.push(file);
            }
        });

        if(images.length > 0){
            setUploadedFiles(images);
        } else {
            setUploadError('Wybrany plik to nie zdjęcie');
        }

    }

    const handlePhotos = e => {

        if (!e.target.files || e.target.files.length === 0) {
            setUploadedFiles([]);
            return
        }

        const chosenFiles = Array.prototype.slice.call(e.target.files);

        handleUploadFiles(chosenFiles);

    }
    
    const handleUploadFiles = files => {

        const uploaded = [];

        let imageTypes = ['image/png', 'image/gif', 'image/bmp', 'image/jpeg', 'image/jpg'];

        files.forEach(file => {
            if(imageTypes.includes(file.type)){
                uploaded.push(file);
            }
        });

        setUploadedFiles(uploaded);

    }

    // Podgląd zdjęć

    const [uploadedPreviews, setUploadedPreviews] = useState([]);

    const [activePreview, setActivePreview] = useState(null);

    useEffect(() => {

        if(uploadedFiles.length > 0){

            const urls = [];

            uploadedFiles.forEach(file => {

                const objectUrl = URL.createObjectURL(file);

                urls.push(objectUrl);
                
            });

            setUploadedPreviews(urls);

            setActivePreview(0);

        }

    }, [uploadedFiles]);

    function changeActivePreview(field, type){

        let last;
    
        let current;

        if(field === 'upload'){

            if(uploadedPreviews.length === 1){
                return;
            }
    
            last = uploadedPreviews.length -1;
    
            current = activePreview;

        } else if(field === 'view'){

            last = photosToShow.length -1;
    
            current = activePhotoShow;

        }

        let new_value;

        if(type === 'decrement'){

            if(current === 0){

                new_value = last;

            } else {

                new_value = current -1;

            }

        } else {

            if(current === last){

                new_value = 0;

            } else {

                new_value = current +1;

            }

        }

        if(field === 'upload'){

            setActivePreview(new_value);

        } else if(field === 'view'){

            setActivePhotoShow(new_value);

        }

    }

    function cancelPhotoSend(){

        closeModal();

    }

    function closePhotoView(){

        setPhotosToShow([]);
        setActivePhotoShow(null);
        closeModal();

    }

    const [activeUpload, setActiveUpload] = useState(null);

    function uploadPhotos(){

        setActiveUpload(true);

        const data = new FormData();

        uploadedFiles.forEach((file, index )=> data.append(index, file));

        if(activeJob.station_id){

            data.append('train_id', activeTrain.train_id);

            data.append('station_id', activeJob.station_id);

        }

        if(activeJob.train_id){

            data.append('train_id', activeJob.train_id);

            data.append('station_id', activeStation);

        }

        data.append('personal_id', activeJob.personal_id);

        data.append('job_number', activeJob.job_number);

        Axios.post('classes/uploads.php', data, { timeout: 30000 }).then(function(response){
                if(Array.isArray(response.data)){
                    updateTrainsPhotos(response.data);
                    setUploadedFiles([]);
                    setUploadedPreviews([]);
                    setActivePreview(null);
                    setUploadError(null);
                } else {
                    setActiveUpload(false);
                    setModalErrorMessage('Wystąpił problem z przesyłaniem zdjęć. Spróbuj ponownie, a jeśli problem będzie się powtarzał skontaktuj się z koordynatorem projektu.')
                    setModal({...modal, show: true, error: true})
                }
                
            }).catch((error) => {
                setActiveUpload(false);
                setModalErrorMessage('Wystąpił problem z przesyłaniem zdjęć. Spróbuj ponownie, a jeśli problem będzie się powtarzał skontaktuj się z koordynatorem projektu.')
                setModal({...modal, show: true, error: true})
                console.warn(error);
            });

    }

    function updateTrainsPhotos(data){

        const outputArray = [];

        data.forEach(item => {

            const outputObject = {
                filename: item,
                job_number: activeJob.job_number,
                personal_id: activeJob.personal_id,
                station_id: activeJob.station_id ? activeJob.station_id : activeStation,
                train_id: activeTrain.train_id ? activeTrain.train_id : activeJob.train_id
            };

            outputArray.push(outputObject);
            
        });

        const updatedMergedTrains = mergedTrains.map(train => {

            if(activeJob.station_id){
                if(train.train_id === activeTrain.train_id){
                    train.photos.push(...outputArray);
                }
            }

            if(activeJob.train_id){
                train.photos.push(...outputArray);
            }

            return train;

        });

        setMergedTrains(updatedMergedTrains)
       
    }

    // Dodatkowy komentarz

    const [additionalComment, setAdditionalComment] = useState('');

    function handleComment(e){

        let value = e.target.value;

        setAdditionalComment(value);

    }

    // Wysyłanie formularza

    const [formErrors, setFormErrors] = useState([]);

    function sendJobForm(){

        // Sprawdzenie czy są podane wszystkie wymagane wartości

        const errors = [];

        if(measurementFormSummary.entered_sum === ''){
            errors.push('podaj liczbę wsiadających');
        }

        if(measurementFormSummary.exited_sum === ''){
            errors.push('podaj liczbę wysiadających');
        }

        if(accuracy === ''){
            errors.push('określ dokładność pomiaru');
        }

        if(errors.length > 0){
            setModal({...modal, show: true, info: true})
            setFormErrors(errors);
            return;
        }

        let button = document.getElementById('measurement-save-button');

        button.setAttribute("disabled", "disabled");

        const measurements = {};

        measurements.entered_exited = measurementFormData;

        measurements.entered_sum = measurementFormSummary.entered_sum;

        measurements.exited_sum = measurementFormSummary.exited_sum;

        measurements.times = time;

        measurements.accuracy = accuracy;

        if(additionalComment){
            measurements.comment = additionalComment;
        }

        let train_id;

        let station_id;

        if(activeJob.station_id){

            train_id = activeTrain.train_id;

            station_id = activeJob.station_id;            

        }

        if(activeJob.train_id){

            train_id = activeJob.train_id;

            station_id = activeStation;

        }

        let job_number = activeJob.job_number;

        let personal_id = activeJob.personal_id;

        let recording_date = activeJob.recording_date;

        let request_type = 'put measurements';

        Axios.post('classes/measurements.php', { personal_id, job_number, recording_date, train_id, station_id, measurements, request_type }, { timeout: 10000 }).then(function(response){
            if(response.data === true){
                
                setModalMessage('Pomiar został zapisany.');

                setLatest({});

                // Dopisywanie pomiaru do obiektu - jeśli mierzona jest stacja

                if(activeJob.station_id){

                    const measurements_to_merge = {
                        arrival_time: time.arrival,
                        departure_time: time.departure,
                        accuracy: accuracy,
                        entered_sum: measurementFormSummary.entered_sum,
                        exited_sum: measurementFormSummary.exited_sum
                    }

                    if(additionalComment){
                        measurements_to_merge.comments = additionalComment;
                    }

                    const merged_measurements = Object.assign({}, measurementFormData, measurements_to_merge);

                    const updated = mergedTrains.map(train => {

                        if(train.train_id === train_id){
                          
                            return {

                                ...train,  
                                
                                measurement: merged_measurements,
                                
                            };

                        } else {

                            return train;

                        }

                    });

                    setMergedTrains(updated);
        
                }

                // Dopisywanie pomiaru - jeśli mierzony jest pociąg

                if(activeJob.train_id){

                    const arr = [];

                    const current_train = mergedTrains[0];

                    const current_measurements = current_train.measurement;

                    const index = current_measurements.findIndex(p => p.station_id === station_id);

                    const measurements_to_merge = {
                        arrival_time: time.arrival,
                        departure_time: time.departure,
                        accuracy: accuracy,
                        entered_sum: measurementFormSummary.entered_sum,
                        exited_sum: measurementFormSummary.exited_sum,
                        station_id: station_id
                    }

                    if(additionalComment){
                        measurements_to_merge.comments = additionalComment;
                    }

                    const merged_measurements = Object.assign({}, measurementFormData, measurements_to_merge);

                    //measurements.station_id = station_id;

                    if(index === -1) {
                        current_measurements.push(merged_measurements);
                    } else {
                        current_measurements[index] = merged_measurements;
                    }

                    current_train.measurement = current_measurements;

                    arr.push(current_train);

                    setMergedTrains(arr);
        
                }

                let list = document.querySelectorAll('.currently-measured-train');

                if(list.length > 0){

                    let element = list[0];
                    element.scrollIntoView();

                }

                clearJobFormData();
                setActiveTrain({});
                setActiveStation(null);
            } else if(response.data === false){
                setModalMessage('Wystąpił problem z zapisem pomiaru. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z koordynatorem projektu.');
            } else {
                setModalMessage('Wystąpił problem z zapisem pomiaru. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z koordynatorem projektu.');
            }
            setModal({...modal, show: true, info: true});
            button.removeAttribute("disabled");
        }).catch((error) => {
            console.warn(error);
            setModalMessage('Wystąpił problem z zapisem pomiaru. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z koordynatorem projektu.');
            setModal({...modal, show: true, info: true});
            button.removeAttribute("disabled");
        });
        
    }

    function clearJobFormData(){

        setMeasurementFormData({});

        setTime({arrival: "", departure: ""});

        setAccuracy('');

        setAdditionalComment('');

    }

    // Wyznaczanie klasy wiersza w tabeli

    const getClassNames = (train_id, measurement) => {

        if(train_id === activeTrain.train_id){

          return 'currently-measured-train';

        } else if(measurement !== null){

          return 'previously-measured-train';

        } else {

          return '';

        }

    }

    const getStationClassNames = (station_id) => {

        const old_measurement = mergedTrains[0].measurement.filter(obj => {return obj.station_id === station_id});

        if(station_id === activeStation){

            return 'currently-measured-train';

        } else if(old_measurement.length > 0){

            return 'previously-measured-train';

        } else {

            const all_stations = mergedTrains[0].stations;

            const current_station = all_stations.filter(innerArray => innerArray[0] === station_id);

            let is_measureable = current_station[0][7];

            if(is_measureable){

                return '';

            } else {

                return 'not-measured-train';

            }

        }

    }

    function getStationName(){

        const found_station = stations.find(u => u.station_id === activeStation);

        return found_station.name;

    }

    // Podgląd zdjęć

    const [photosToShow, setPhotosToShow] = useState([]);

    const [activePhotoShow, setActivePhotoShow] = useState(null);

    function showPhotos(train_id){

        const train = mergedTrains.find(u => u.train_id === train_id);

        const photos = train.photos;

        photos.forEach(photo => {

            let train_id = photo.train_id;

            let station_id = photo.station_id;

            photo.url = '/photos/trains/' + photo.filename;

            const train_match = trainNumbers.find(u => u.train_id === train_id);

            const station_match = stations.find(u => u.station_id === station_id);

            if(train_match && station_match){

                photo.name = 'Pociąg ' + train_match.train_number + ' na stacji ' + station_match.name;

            }

        });

        setPhotosToShow(photos);

        setActivePhotoShow(0);

        setModal({...modal, show: true, show_photo: true});

    }

    const [viewModalSlides, setViewModalSlides] = useState([]);

    useEffect(() => {
        setViewModalSlides(
            photosToShow.map((image) => {
                return { src: image.url };
            })
        );
    }, [photosToShow]);

    // Zachowanie ostatniego stanu

    const [previousLoad, setPreviousLoad] = useState(false);

    const [measurementSaving, setMeasurementSaving] = useState(false);

    const latestMeasurementSaving = useRef();

    useEffect(() => {

        latestMeasurementSaving.current = measurementSaving;

    }, [measurementSaving]);

    // Zachowywanie w pamięci wybranego zadania

    useEffect(() => {

        if(activeJob){

            db.latest.toArray().then(function(result){

                if(result.length > 0){

                    db.latest.update(1, {job: activeJob});

                } else {

                    db.latest.put({index: 1, job: activeJob});

                }

            });

        }

    }, [activeJob]);

    // Zachowywanie pomiarów jeśli pociąg/stacja mierzona jest pierwszy raz

    useEffect(() => {

        if(latestMeasurementSaving.current){

            const current_train = latestActiveTrain.current;

            const current_station = latestActiveStation.current;

            const updated = {
                measurementFormData: measurementFormData,
                time: time,
                accuracy: accuracy,
                additionalComment: additionalComment
            }

            db.latest.update(1, {train: current_train, station: current_station, measurements: updated});

        }

    }, [measurementFormData, time, accuracy, additionalComment]);

    // Aktywowanie wcześniejszego zadania przy pierwszym wczytaniu strony

    const [latest, setLatest] = useState({});

    useEffect(() => {

        if(updatedJobs.length > 0 && !previousLoad){

            db.latest.toArray().then(function(result){

                if(result.length > 0){

                    const latest_job = result[0].job;

                    const latest_train = result[0].train;

                    const latest_station = result[0].station;

                    const latest_measurments = result[0].measurements;

                    if(latest_job){

                        let job_number = latest_job.job_number;

                        if(updatedJobs.some(job => job.job_number === job_number)){

                            activateJob(job_number)

                        }

                        if((latest_train || latest_station) && latest_measurments){

                            setLatest({train: latest_train, station: latest_station, measurements: latest_measurments});

                        }

                    }

                }

            });

        }

        // eslint-disable-next-line
    }, [updatedJobs]);

    useEffect(() => {

        if(mergedTrains.length > 0 && Object.keys(latest).length > 0){

            setMeasurementSaving(true);

            const latest_train = latest.train;

            const latest_station = latest.station;

            const latest_measurments = latest.measurements;

            if(latest_train){
                activateSelectedTrain(latest_train);
                //setActiveTrain(latest_train);
            }

            if(latest_station){
                activateSelectedStation(latest_station);
                //setActiveStation(latest_station);
            }

            setMeasurementFormData(latest_measurments.measurementFormData);

            setTime(latest_measurments.time);

            setAccuracy(latest_measurments.accuracy);

            setAdditionalComment(latest_measurments.additionalComment);

        }
        // eslint-disable-next-line
    }, [mergedTrains, latest]);

    // Pełny rozkład jazdy pociągów

    function openSchedules(){

        if(activeJob.station_id){

            db.station.put({index: 1, station: activeJob.station_id});

            window.open('/rozklady', '_blank');

        }

    }

    // Raportowanie czasu pracy

    const [customTime, setCustomTime] = useState({
        job_number: null,
        hours: '',
        minutes: ''
    });

    function showCustomTime(job_number){

        setCustomTime({
            job_number: job_number,
            reported_hours: '',
            reported_minutes: ''
        });

        setModal({...modal, show: true, time: true});

    }

    function reportedTimeChange(event){

        const {name, value} = event.target;

        let new_value = Number(value);

        let new_calculated;

        if(name === 'reported_minutes'){

            if(new_value < 0){
                new_calculated = '00';
            } else if(new_value >= 0 && new_value < 10){
                new_calculated = '0' + new_value;
            } else if(new_value >= 10 && new_value < 60){
                new_calculated = String(new_value);
            } else if(new_value >= 60){
                new_calculated = '59';
            } 

        }

        if(name === 'reported_hours'){

            if(new_value <= 0){
                new_calculated = '0';
            } else if(new_value > 0 && new_value < 50){
                new_calculated = String(new_value);
            } else if(new_value >= 50){
                new_calculated = '50';
            }

        }

        setCustomTime(prevFormData => {
            return {
                ...prevFormData,
                [name]: new_calculated
            }
        });

    }

    function cancelTimeReport(){
        closeModal();
        setCustomTime({
            job_number: null,
            reported_hours: '',
            reported_minutes: ''
        });
    }

    function reportTime(){

        if(customTime.reported_hours === '' && customTime.reported_minutes === ''){
            return;
        }

        let job_number = customTime.job_number;

        let hours = customTime.reported_hours;

        let minutes = customTime.reported_minutes;

        let personal_id = user.personal_id;

        let request_type = 'report time';

        Axios.post('classes/measurements.php', { personal_id, job_number, hours, minutes, request_type }, { timeout: 10000 }).then(function(response){

            if(response.data.reported_time){
                
                setModalMessage('Czas został zapisany.');

                setCustomTime({
                    job_number: null,
                    reported_hours: '',
                    reported_minutes: ''
                });

                let reported_time = response.data.reported_time

                const updated = filteredJobs.map(job => {

                    if(job.job_number === job_number){
                      
                        return {

                            ...job,  
                            
                            reported_work_hours: reported_time,
                            
                        };

                    } else {

                        return job;

                    }

                });

                setFilteredJobs(updated);

            } else {
                setModalMessage('Wystąpił problem z zapisem. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z koordynatorem projektu.');
            }
            setModal({...modal, show: true, time: false, info: true});
        }).catch((error) => {
            console.warn(error);
            setModalMessage('Wystąpił problem z zapisem. Spróbuj ponownie, a jeżeli problem będzie się powtarzał skontaktuj się z koordynatorem projektu.');
            setModal({...modal, show: true, time: false, info: true});
        });

    }

    return (
        <div id="app-outer-container">
            {(appLayer === 100 || appLayer === 200) && 
                <div className="user-top-panel-outer-wrapper">
                    <div className="user-top-panel-middle-wrapper">
                        <div className="user-top-panel-inner-wrapper">
                            <p className="work-panel-top-text">Witaj {user.first_name + " " + user.surname}</p>
                        </div>
                        <div className="separator"></div>
                        <div className="user-top-panel-inner-wrapper">
                            <p className="work-panel-top-text">Łączny czas pracy: <span className="top-panel-important-info">{formatedTotalTime}</span></p>
                            {activeJob && <p className="work-panel-top-text">Czas pracy w zadaniu: <span className="top-panel-important-info">{formatedJobTime}</span></p>}
                        </div>
                    </div>
                    <div className="user-top-panel-middle-wrapper">
                        <div className="user-top-panel-inner-wrapper">
                            <button className={!activeStatus ? "user-top-panel-button user-top-panel-button-red" : "user-top-panel-button"} onClick={() => handleWorkTime()}>{activeStatus ? 'Zatrzymaj czas' : 'Wznów pracę'}</button>
                        </div>
                        <div className="separator"></div>
                        {activeJob && <><div className="user-top-panel-inner-wrapper">
                            <button className="user-top-panel-button" onClick={() => exitJob()}>Zmień zadanie</button>
                        </div>
                        <div className="separator"></div>
                        </>}
                        <div className="user-top-panel-inner-wrapper">
                            <button className="user-top-panel-button" onClick={logout}>Wyloguj się &#10140;</button>
                        </div>
                    </div>
                </div>
            }
            <div id="app-inner-container">
                {appLayer === 100 && <>
                    {updatedJobs.length === 0 && 
                        <div className="waiting-wrapper">
                            {searching && 
                            <>
                                <p className="waiting-message">Wyszukiwanie dostępnych zadań</p>
                                <Gear/>
                            </>}
                            {!searching && 
                            <>
                                <p className="waiting-message">Brak dostępnych zadań</p>
                                <button className='user-top-panel-button' onClick={()=>getData()}>Sprawdź ponownie</button>
                            </>}                    
                        </div>
                    }
                    {updatedJobs.length > 0 && 
                        <div className="job-selection-outer-wrapper">
                            <div className="job-selection-top-panel-wrapper">
                                {jobDates.length > 0 && 
                                    <div className="job-selection-filter-wrapper">
                                        <p className="job-selection-filter-label">Data pomiarów</p>
                                        <select
                                            onChange={onJobFilterChange}
                                            value={jobFilters.job_date}
                                            name='job_date'
                                            className="filter-select"
                                            id="user-job-date"
                                        >
                                            <option value=''>Wszystkie</option>
                                            {jobDates.map((date, index) => (
                                            <option key={index} value={date}>{date}</option>
                                            ))}
                                        </select>
                                    </div>
                                }
                                {jobStations.length > 0 &&
                                    <div className="job-selection-filter-wrapper">
                                        <p className="job-selection-filter-label">Nazwa stacji</p>
                                        <select
                                            onChange={onJobFilterChange}
                                            value={jobFilters.job_station_name}
                                            name='job_station_name'
                                            className="filter-select"
                                            id="user-job-station"
                                        >
                                            <option value=''>Wszystkie</option>
                                            {jobStations.map((station, index) => (
                                            <option key={index} value={station}>{station}</option>
                                            ))}
                                        </select>
                                    </div>
                                }
                                <button className="clear-job-filters-button" onClick={() => setJobFilters({job_station_name: '', job_date: ''})}>Pokaż wszystkie</button>
                            </div>
                            <div className="job-selection-bottom-panel-wrapper">
                                <table className="job-details-table">
                                    <thead>
                                        <tr>
                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">ID</span></th>
                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">Punkt/Pociąg</span></th>
                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">Data</span></th>
                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">Liczba<br></br>pociągów<br></br>/ stacji</span></th>
                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">Liczba odcz.<br></br>pociągów<br></br>/ stacji</span></th>
                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">% wykonania</span></th>
                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">Informacje<br></br>dodatkowe</span></th>
                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">Czas pracy</span></th>
                                            <th><div className="job-details-table-header-cell"></div><span className="job-details-table-span">Wybierz</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredJobs.map((job, index) =>
                                            <tr key={index} className={job.stages === job.completed_stages ? "job-completed-row" : ""}>
                                                <td>{job.job_number}</td> 
                                                <td>{job.station_name ? job.station_name : job.first_station_name + ' \u2014 ' + job.last_station_name}</td>
                                                <td>{formatDate(job.recording_date)}</td>
                                                <td>{job.stages}</td>
                                                <td>{job.completed_stages ? job.completed_stages : '0'}</td>
                                                <td>{!job.completed_stages ? "0" : ((job.completed_stages/job.stages)*100).toFixed(2)}</td>
                                                <td>{job.comments ? job.comments : 'brak'}</td>
                                                {job.stages !== job.completed_stages && <td>{job.work_hours ? job.work_hours : '-'}</td>}
                                                {job.stages === job.completed_stages && !job.reported_work_hours && <td>{job.work_hours}<button onClick={() => showCustomTime(job.job_number)} className="job-time-update-button">raportuj</button></td>}
                                                {job.stages === job.completed_stages && job.reported_work_hours && <td><span className="table-reported-time-span">{job.work_hours}</span><span className="table-reported-time-span">{job.reported_work_hours}</span></td>}
                                                <td><button className="job-selection-button" onClick={() => activateJob(job.job_number)}>&#10000;</button></td>
                                            </tr>
                                        )}
                                        {filteredJobs.length === 0 && 
                                            <tr>
                                                <td colSpan={9}>Brak zadań spełniających wybrane kryteria</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table> 
                            </div>        
                        </div>
                    }
                </>}
                {appLayer === 200 && 
                    <div id="job-working-outer-container" className={(activeTrain.id || activeStation) ? "" : "job-working-outer-extended"}>
                        <h1 className="section-title">Zadanie nr {activeJob.job_number}</h1>

                        <div className="job-working-top-outer-wrapper">
                            <div className="job-working-top-inner-wrapper">
                                <p className="job-working-top-text">Data pomiarów: <span className="job-info-important">{formatDate(activeJob.recording_date)}</span></p>
                                {activeJob.station_name && <p className="job-working-top-text">Badany punkt: <span className="job-info-important">{activeJob.station_name}</span></p>}
                                {activeJob.station_name && activeJob.stages !== null && activeJob.stages !== undefined && <p className="job-working-top-text">Liczba pociągów: <span className="job-info-important">{activeJob.stages}</span></p>}
                                {activeJob.train_number && <p className="job-working-top-text">Numer pociągu: <span className="job-info-important">{activeJob.train_number}</span></p>}
                                {activeJob.first_station_name && activeJob.last_station_name && <p className="job-working-top-text">Relacja: <span className="job-info-important">{activeJob.first_station_name + " - " + activeJob.last_station_name}</span></p>}
                            </div>
                            <div className="job-working-top-2-inner-wrapper">
                                {cameraSlides.length > 0 && <button className="job-additional-button" onClick={() => setModal({...modal, show: true, cameras_photos: true})}>Podgląd kamer</button>}
                                {ftpList.length > 0 && <button className="job-additional-button" onClick={() => setModal({...modal, show: true, ftp_info: true})}>Pobierz filmy</button>}
                                {activeJob.train_id && mergedTrains.length > 0 && (mergedTrains.find(u => u.train_id === activeJob.train_id).photos.length > 0) && <button onClick={() => showPhotos(activeJob.train_id)} className="job-additional-button">Zobacz zdjęcia</button>}
                            </div>
                        </div>
                        {/* Sprawdzanie stacji */}
                        {activeJob.station_name && 
                            <div className="job-working-middle-outer-wrapper">
                                <div className="job-working-table-wrapper">
                                    <table className="job-working-details-table">
                                        <thead>
                                            <tr>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Pociąg</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Peron</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Tor</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Przyjazd</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Odjazd</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Relacja</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">&sum; wsiadło</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">&sum; wysiadło</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Zdjęcia</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Zmiany w<br></br>rozkładzie</span></th>
                                                <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Analizuj</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {mergedTrains.map((train, index) =>
                                                <tr key={index} className={`${getClassNames(train.train_id, train.measurement)}`}>
                                                    <td>{train.train_number}</td>
                                                    <td>{train['platform_number_'+train.station_index] ? train['platform_number_'+train.station_index] : "b/d"}</td>
                                                    <td>{train['lane_number_'+train.station_index] ? train['lane_number_'+train.station_index] : "b/d"}</td>
                                                    <td>{train['arrival_hour_'+train.station_index] ? train['arrival_hour_'+train.station_index] : "-"}</td>
                                                    <td>{train['departure_hour_'+train.station_index] ? train['departure_hour_'+train.station_index] : "-"}</td>
                                                    <td>{train.first_station_name + " - " + train.last_station_name}</td>
                                                    <td>{train.measurement && train.measurement.entered_sum}</td>
                                                    <td>{train.measurement && train.measurement.exited_sum}</td>
                                                    <td>{train.photos[0] ? <button className="job-working-train-icon" onClick={() => showPhotos(train.train_id)}><Icon2/></button> : "brak"}</td>
                                                    <td>{train.delay ? train.delay : "-"}</td>
                                                    <td><button className="job-working-train-icon" onClick={() => activateTrain(train.id)}><Icon1/></button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table> 
                                </div>
                                {activeJob.train_list !== '' && activeJob.train_list !== null && <div>
                                    <p onClick={() => openSchedules()} id="full-schedule-link">Pełny rozkład jazdy na stacji &#10132;</p>
                                </div>}
                            </div>
                        }
                        {/* Sprawdzanie pociągu */}
                        {activeJob.train_number && <div className="job-working-middle-outer-wrapper">
                            <div className="job-working-table-wrapper">
                                <table className="job-working-details-table">
                                    <thead>
                                        <tr>
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Stacja</span></th>
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Peron</span></th>
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Tor</span></th>
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Przyjazd</span></th>
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Odjazd</span></th>
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">&sum; wsiadło</span></th>
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">&sum; wysiadło</span></th>
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Zmiany w<br></br>rozkładzie</span></th>
                                            <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Analizuj</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mergedTrains[0] && mergedTrains[0].stations.map((station, index) =>
                                            <tr key={index} className={`${getStationClassNames(station[0])}`}>
                                                <td>{station[1]}</td>
                                                <td>{station[4] ? station[4] : "b/d"}</td>
                                                <td>{station[5] ? station[5] : "b/d"}</td>
                                                <td>{station[2] ? station[2] : '-'}</td>
                                                <td>{station[3] ? station[3] : '-'}</td>
                                                {station[7] === false && <td>-</td>}
                                                {station[7] === true && <td>
                                                    {mergedTrains[0].measurement.map((measurement, i) =>
                                                        <p key={i} className="station-measurements-line">
                                                            {measurement.station_id === station[0] && <span>{measurement.entered_sum}</span>}
                                                        </p>
                                                    )}
                                                </td>}
                                                {station[7] === false && <td>-</td>}
                                                {station[7] === true && <td>
                                                    {mergedTrains[0].measurement.map((measurement, i) =>
                                                        <p key={i} className="station-measurements-line">
                                                            {measurement.station_id === station[0] && <span>{measurement.exited_sum}</span>}
                                                        </p>
                                                    )}
                                                </td>}
                                                <td>{station[6] ? station[6] : '-'}</td>
                                                <td>{station[7] === true ? <button className="job-working-train-icon" onClick={() => activateStation(station[0])}><Icon1/></button> : "-"}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table> 
                            </div>
                        </div>}
                        {(activeTrain.id || activeStation) && 
                            <div className="job-working-bottom-outer-wrapper">
                                <div className="measurement-title">
                                    {activeTrain.id && <h2 className="section-title">Pociąg {activeTrain.train_number}<br></br>{activeTrain.first_station_name} - {activeTrain.last_station_name}</h2>}
                                    {activeStation && <h2 className="section-title">Stacja {getStationName()}</h2>}
                                </div>
                                <div className="entered-exited-form-outer-container">
                                    <div className="entered-exited-form-middle-wrapper">
                                        <div className="entered-exited-form-inner-wrapper">
                                            <p className="measuring-form-label measuring-form-label-fixed-width">Wsiadło</p>
                                            <div className="entered-exited-outer-line-wrapper">
                                                <div className="entered-exited-line-wrapper">
                                                    <input 
                                                        className="input-number" 
                                                        type="number" 
                                                        min="0" 
                                                        onChange={(e) =>measurementFormChange(e)}
                                                        id={'entered_1'}
                                                        name={'entered_1'}
                                                        value={measurementFormData.entered_1 !== undefined ? measurementFormData.entered_1 : ""}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                    <input 
                                                        className="input-number" 
                                                        type="number" 
                                                        min="0" 
                                                        onChange={(e) =>measurementFormChange(e)}
                                                        id={'entered_2'}
                                                        name={'entered_2'}
                                                        value={measurementFormData.entered_2 !== undefined ? measurementFormData.entered_2 : ""}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                    <input 
                                                        className="input-number" 
                                                        type="number" 
                                                        min="0" 
                                                        onChange={(e) =>measurementFormChange(e)}
                                                        id={'entered_3'}
                                                        name={'entered_3'}
                                                        value={measurementFormData.entered_3 !== undefined ? measurementFormData.entered_3 : ""}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </div>
                                                <div className="entered-exited-line-wrapper">
                                                    <input 
                                                        className="input-number" 
                                                        type="number" 
                                                        min="0" 
                                                        onChange={(e) =>measurementFormChange(e)}
                                                        id={'entered_4'}
                                                        name={'entered_4'}
                                                        value={measurementFormData.entered_4 !== undefined ? measurementFormData.entered_4 : ""}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                    <input 
                                                        className="input-number" 
                                                        type="number" 
                                                        min="0" 
                                                        onChange={(e) =>measurementFormChange(e)}
                                                        id={'entered_5'}
                                                        name={'entered_5'}
                                                        value={measurementFormData.entered_5 !== undefined ? measurementFormData.entered_5 : ""}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                    <input 
                                                        className="input-number" 
                                                        type="number" 
                                                        min="0" 
                                                        onChange={(e) =>measurementFormChange(e)}
                                                        id={'entered_6'}
                                                        name={'entered_6'}
                                                        value={measurementFormData.entered_6 !== undefined ? measurementFormData.entered_6 : ""}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </div>
                                            </div>
                                            <div className="entered-exited-sum-wrapper">
                                                <input 
                                                    className="input-number input-number-sum" 
                                                    type="number" 
                                                    min="0" 
                                                    id={'entered_sum'}
                                                    name={'entered_sum'}
                                                    value={measurementFormSummary.entered_sum !== '' ? measurementFormSummary.entered_sum : ""}
                                                    readOnly
                                                />
                                                <p className="measuring-form-label">&sum;</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="entered-exited-form-middle-wrapper">
                                        <div className="entered-exited-form-inner-wrapper">
                                            <p className="measuring-form-label measuring-form-label-fixed-width">Wysiadło</p>
                                            <div className="entered-exited-outer-line-wrapper">
                                                <div className="entered-exited-line-wrapper">
                                                    <input 
                                                        className="input-number" 
                                                        type="number" 
                                                        min="0" 
                                                        onChange={(e) =>measurementFormChange(e)}
                                                        id={'exited_1'}
                                                        name={'exited_1'}
                                                        value={measurementFormData.exited_1 !== undefined ? measurementFormData.exited_1 : ""}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                    <input 
                                                        className="input-number" 
                                                        type="number" 
                                                        min="0" 
                                                        onChange={(e) =>measurementFormChange(e)}
                                                        id={'exited_2'}
                                                        name={'exited_2'}
                                                        value={measurementFormData.exited_2 !== undefined ? measurementFormData.exited_2 : ""}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                    <input 
                                                        className="input-number" 
                                                        type="number" 
                                                        min="0" 
                                                        onChange={(e) =>measurementFormChange(e)}
                                                        id={'exited_3'}
                                                        name={'exited_3'}
                                                        value={measurementFormData.exited_3 !== undefined ? measurementFormData.exited_3 : ""}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </div>
                                                <div className="entered-exited-line-wrapper">
                                                    <input 
                                                        className="input-number" 
                                                        type="number" 
                                                        min="0" 
                                                        onChange={(e) =>measurementFormChange(e)}
                                                        id={'exited_4'}
                                                        name={'exited_4'}
                                                        value={measurementFormData.exited_4 !== undefined ? measurementFormData.exited_4 : ""}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                    <input 
                                                        className="input-number" 
                                                        type="number" 
                                                        min="0" 
                                                        onChange={(e) =>measurementFormChange(e)}
                                                        id={'exited_5'}
                                                        name={'exited_5'}
                                                        value={measurementFormData.exited_5 !== undefined ? measurementFormData.exited_5 : ""}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                    <input 
                                                        className="input-number" 
                                                        type="number" 
                                                        min="0" 
                                                        onChange={(e) =>measurementFormChange(e)}
                                                        id={'exited_6'}
                                                        name={'exited_6'}
                                                        value={measurementFormData.exited_6 !== undefined ? measurementFormData.exited_6 : ""}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </div>
                                            </div>
                                            <div className="entered-exited-sum-wrapper">
                                                <input 
                                                    className="input-number input-number-sum" 
                                                    type="number" 
                                                    min="0" 
                                                    id={'exited_sum'}
                                                    name={'exited_sum'}
                                                    value={measurementFormSummary.exited_sum !== "" ? measurementFormSummary.exited_sum : ""}
                                                    readOnly
                                                />
                                                <p className="measuring-form-label">&sum;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hours-form-outer-wrapper">
                                    <div className="hours-form-additional-wrapper">
                                        <div className="hours-form-wrapper">
                                            <p className="measuring-form-label measuring-form-label-fixed-width-2">Godzina przyjazdu</p>
                                            <div className="hours-inner-wrapper">
                                                <div className="hours-buttons-wrapper">
                                                    <div className="hours-button" onClick={() => manualTimeChange('increment', 'hour', 'arrival')}><span className="plus-sign">+</span></div>
                                                    <div className="hours-button" onClick={() => manualTimeChange('decrement', 'hour', 'arrival')}><span className="minus-sign">-</span></div>
                                                </div>
                                                <input
                                                    className="input-number input-time"
                                                    type="time"
                                                    id="arrival"
                                                    name="arrival"
                                                    value={time.arrival}
                                                    onChange={handleTimeChange}
                                                    step="60" 
                                                />
                                                <div className="hours-buttons-wrapper">
                                                    <div className="hours-button" onClick={() => manualTimeChange('increment', 'minute', 'arrival')}><span className="plus-sign">+</span></div>
                                                    <div className="hours-button" onClick={() => manualTimeChange('decrement', 'minute', 'arrival')}><span className="minus-sign">-</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hours-form-wrapper">
                                            <p className="measuring-form-label measuring-form-label-fixed-width-2">Godzina odjazdu</p>
                                            <div className="hours-inner-wrapper">
                                                <div className="hours-buttons-wrapper">
                                                    <div className="hours-button" onClick={() => manualTimeChange('increment', 'hour', 'departure')}><span className="plus-sign">+</span></div>
                                                    <div className="hours-button" onClick={() => manualTimeChange('decrement', 'hour', 'departure')}><span className="minus-sign">-</span></div>
                                                </div>
                                                <input
                                                    className="input-number input-time"
                                                    type="time"
                                                    id="departure"
                                                    name="departure"
                                                    value={time.departure}
                                                    onChange={handleTimeChange}
                                                    step="60" 
                                                />
                                                <div className="hours-buttons-wrapper">
                                                    <div className="hours-button" onClick={() => manualTimeChange('increment', 'minute', 'departure')}><span className="plus-sign">+</span></div>
                                                    <div className="hours-button" onClick={() => manualTimeChange('decrement', 'minute', 'departure')}><span className="minus-sign">-</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hours-form-bottom-info-wrapper"><p className="hours-form-additional-info">Wprowadź rzeczywisty czas odjazdu/przyjazdu tylko gdy różnica przekracza 10 minut</p></div>
                                </div>
                                <div className="form-slider-outer-wrapper">
                                    <div id="slider-info-parent">
                                        <p className="measuring-form-label" onMouseOver={() => setSliderInfo(true)} onMouseOut={() => setSliderInfo(false)}>Dokładność pomiaru<span id="info-slider-span">&#9432;</span></p>
                                        {sliderInfo &&
                                        <div id="slider-info-child">
                                            <p className="slider-info-child-paragraph">99% - możliwy błąd 1 osoby na 100</p>
                                            <p className="slider-info-child-paragraph">95% - możliwy błąd 1 osoby na 25</p>
                                            <p className="slider-info-child-paragraph">90% - możliwy błąd 1 osoby na 10</p>
                                            <p className="slider-info-child-paragraph">80% - możliwy błąd 2 osoby na 10</p>
                                            <p className="slider-info-child-paragraph">Uwaga - wymagana jest dokładność pomiaru na poziomie 99-95%.</p>
                                            <p className="slider-info-child-paragraph">Wyższy poziom błędu jest dopuszczalny tylko jeśli jakość obrazu nie pozwala na dokładne policzenie osób.</p>
                                        </div>
                                        }
                                    </div>
                                    <div className="form-slider-inner-wrapper">
                                        <input
                                            id="accuracy-slider"
                                            type="range"
                                            min={1}
                                            max={5}
                                            step={1}
                                            value={accuracy === '' ? 1 : accuracy}
                                            onChange={handleAccuracyChange}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                        <div className="form-slider-info-outer-wrapper">
                                            <div className="form-slider-info-inner-wrapper">
                                                <span className="form-slider-span">&#10072;</span>
                                                <span className="form-slider-span form-slider-span-lower">&lt;80%</span>
                                            </div>
                                            <div className="form-slider-info-inner-wrapper">
                                                <span className="form-slider-span">&#10072;</span>
                                                <span className="form-slider-span form-slider-span-lower">80%</span>
                                            </div>
                                            <div className="form-slider-info-inner-wrapper">
                                                <span className="form-slider-span">&#10072;</span>
                                                <span className="form-slider-span form-slider-span-lower">90%</span>
                                            </div>
                                            <div className="form-slider-info-inner-wrapper">
                                                <span className="form-slider-span">&#10072;</span>
                                                <span className="form-slider-span form-slider-span-lower">95%</span>
                                            </div>
                                            <div className="form-slider-info-inner-wrapper">
                                                <span className="form-slider-span">&#10072;</span>
                                                <span className="form-slider-span form-slider-span-lower">99%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="form-slider-outer-wrapper">
                                    <p className="measuring-form-label">Dokładność pomiaru</p>
                                    <div className="form-slider-inner-wrapper">
                                        <span className="form-slider-label">bardzo zła</span>
                                        <input
                                            type="range"
                                            min={1}
                                            max={10}
                                            step={1}
                                            value={accuracy}
                                            onChange={handleAccuracyChange}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                        <span className="form-slider-label">doskonała</span>
                                    </div>
                                </div>*/}
                                <div className="form-additional-fields-outer-wrapper">
                                    <p className="measuring-form-label">Opcjonalnie</p>
                                    <div className="form-additional-fields-inner-wrapper">
                                        <button className="user-top-panel-button add-photos-button" onClick={() => setModal({...modal, show: true, add_photo: true})}>Dodaj zdjęcia</button>
                                        <textarea 
                                            id="add_comment"
                                            type="text"
                                            name="add_comment"
                                            placeholder="Wpisz komentarz do pomiaru"
                                            className="cam-form-field cam-form-field-textarea"
                                            onChange={handleComment}   
                                            value={additionalComment}
                                        />
                                    </div>
                                </div>
                                <div className="job-form-send-button-wrapper">
                                    <button id="measurement-save-button" className={(measurementFormSummary.entered_sum !== '' && measurementFormSummary.exited_sum !== '' && accuracy !== '') ? "user-top-panel-button finish-measurements-button" : "user-top-panel-button finish-measurements-button-disabled"} onClick={() => sendJobForm()}>Zakończ pomiar</button>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
            {modal.show &&
                <div className="modal-overlay" onClick={() => handleModalClose()}>
                    {/* Dodawanie zdjęć */}
                    {modal.add_photo && !modal.error && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div className="modal-header">
                                <h2 className="modal-title">Dodaj zdjęcia</h2>
                            </div>
                            {activeUpload && uploadedFiles.length > 0 &&
                                <div className="modal-body modal-body-centered">
                                    <p className="modal-info-text">Trwa wysyłanie</p>
                                    <Gear/>
                                </div>
                            }
                            {activeUpload && uploadedFiles.length === 0 &&
                                <div className="modal-body modal-body-centered">
                                    <p className="modal-info-text">Wysyłanie zakończone powodzeniem.</p>
                                    <button className="user-top-panel-button" onClick={() => setActiveUpload(false)}>Powróć</button>
                                </div>
                            }
                            {!activeUpload && <div className="modal-body">
                                {uploadedFiles.length === 0 &&
                                    <div id="photo-import-wrapper" >
                                        <label htmlFor="kml-file" className="drop-container" onDragOver={allowDrop} onDrop={handleDrop}>
                                            <span className="drop-title">Przeciągnij plik tutaj</span>
                                            lub
                                            <input
                                                type="text"
                                                id="drop-zone"
                                                placeholder='Wklej zdjęcie tutaj'
                                                className="drop-zone"
                                                onPaste={handlePaste}
                                                readOnly
                                            />
                                            lub
                                            <input 
                                                accept="image/*" 
                                                type="file"
                                                onChange={handlePhotos}
                                                multiple
                                            />
                                        </label>
                                    </div>
                                }
                                {uploadedFiles.length > 0 &&
                                    <div className="photo-preview-wrapper">
                                        {uploadedPreviews.length > 0 && 
                                            <div id="photo-preview-inside-wrapper">
                                                {uploadedPreviews.length > 1 && <svg className="btn btn--prev" onClick={() => changeActivePreview('upload', 'decrement')} height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"/>
                                                    <path d="M0-.5h24v24H0z" fill="none"/>
                                                </svg>}
                                                {activePreview !== null && 
                                                    <img
                                                        src={uploadedPreviews[activePreview]}
                                                        alt='upload-preview'
                                                        className="image-preview"
                                                    />
                                                }
                                                {uploadedPreviews.length > 1 && <svg className="btn btn--next" onClick={() => changeActivePreview('upload', 'increment')} height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/>
                                                    <path d="M0-.25h24v24H0z" fill="none"/>
                                                </svg>}

                                                {/*uploadedPreviews.length > 1 && <p className="image-preview-arrow" onClick={() => changeActivePreview('upload', 'decrement')}><img src={LeftArrow} alt='arrow' className="arrow arrow-left"/></p>*/}
                                                {/*uploadedPreviews.length > 1 && <p className="image-preview-arrow" onClick={() => changeActivePreview('upload', 'increment')}><img src={RightArrow} alt='arrow' className="arrow arrow-right"/></p>*/}
                                            </div>
                                        }
                                    </div>
                                }
                            </div>}
                            {!activeUpload && 
                                <div className="modal-footer">
                                    {uploadError && <p className="photo-error">{uploadError}</p>}
                                    <div className="modal-buttons-wrapper"> 
                                        {uploadedFiles.length > 0 && <button className="user-top-panel-button" onClick={() => uploadPhotos()}>Prześlij {uploadedFiles.length > 1 ? 'zdjęcia' : 'zdjęcie'}</button>}
                                        <button className="user-top-panel-button user-top-panel-button-red" onClick={() => cancelPhotoSend()}>{uploadedFiles.length > 0 ? 'Anuluj wysyłanie' : 'Anuluj'}</button>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {/* Pokazywanie zdjęć */}
                    {modal.show_photo && !modal.error && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div id="modal-header" className="modal-header">
                                <h2 className="modal-title">Podgląd zdjęć</h2>
                                <button id="modal-close-button" onClick={() => closeModal()}>&#10006;</button>
                            </div>
                            <div className="modal-body">
                                {photosToShow.length > 0 &&
                                    <div className="photo-preview-wrapper">
                                        <Carousel>
                                            {photosToShow.map((image, index) =>
                                                <CarouselItem key={index} name={image.name}>
                                                    <div className="carousel-image-wrapper">
                                                        <img onClick={() => handleMarkerGallery(index)} className="camera-image" referrerPolicy="no-referrer" src={image.url} alt="pociąg"/>
                                                    </div>
                                                </CarouselItem>
                                            )}
                                        </Carousel>
                                        <Lightbox
                                            open={openModal}
                                            index={imageIndex}
                                            close={() => setOpenModal(false)}
                                            slides={viewModalSlides}
                                            plugins={[Thumbnails, Zoom]}
                                            zoom={{
                                                maxZoomPixelRatio: 10,
                                                scrollToZoom: true
                                            }}
                                        />
                                        
                                    </div>
                                }
                            </div>
                            
                        </div>
                    }
                    {/* Pokazywanie zdjęć */}
                    {modal.show_photo_old && !modal.error && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div className="modal-header">
                                <h2 className="modal-title">Podgląd zdjęć</h2>
                                
                            </div>
                            <div className="modal-body">
                                {photosToShow.length > 0 &&
                                    <div className="photo-preview-wrapper">

                                        {<div id="photo-preview-inside-wrapper">
                                            {photosToShow.length > 1 && <svg className="btn btn--prev" onClick={() => changeActivePreview('view', 'decrement')} height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"/>
                                                <path d="M0-.5h24v24H0z" fill="none"/>
                                            </svg>}
                                            {/*photosToShow.length > 1 && <p className="image-preview-arrow" onClick={() => changeActivePreview('view', 'decrement')}><img src={LeftArrow} alt='arrow' className="arrow arrow-left"/></p>*/}
                                            {activePhotoShow !== null && 
                                                <a href={"https://analiza.badaniaruchu.pl/photos/"+photosToShow[activePhotoShow].filename} rel="noreferrer" target="_blank" className="image-preview-link"><img
                                                    src={'/photos/'+photosToShow[activePhotoShow].filename}
                                                    alt='upload-preview'
                                                    className="image-preview"
                                                /></a>
                                            }
                                            {photosToShow.length > 1 && <svg className="btn btn--next" onClick={() => changeActivePreview('view', 'increment')} height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/>
                                                <path d="M0-.25h24v24H0z" fill="none"/>
                                            </svg>}
                                            {/*photosToShow.length > 1 && <p className="image-preview-arrow" onClick={() => changeActivePreview('view', 'increment')}><img src={RightArrow} alt='arrow' className="arrow arrow-right"/></p>*/}
                                        </div>}
                                    </div>
                                }
                            </div>
                            <div className="modal-footer">
                                <div className="modal-buttons-wrapper"> 
                                    <button className="user-top-panel-button" onClick={() => closePhotoView()}>Zamknij</button>
                                </div>
                            </div>
                        </div>
                    }
                    {/* Podgląd kamer */}
                    {modal.cameras_photos && !modal.error && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div id="modal-header" className="modal-header">
                                <button id="modal-close-button" onClick={() => closeModal()}>&#10006;</button>
                                <h2 className="modal-title">Podgląd kamer</h2>
                            </div>
                            <div className="modal-body">
                                <div className="photo-preview-wrapper">
                                    <Carousel>
                                        {cameraSlides.map((image, index) =>
                                            <CarouselItem key={index} name={image.name}>
                                                <div className="carousel-image-wrapper">
                                                    <img onClick={() => handleMarkerGallery(index)} className="camera-image" referrerPolicy="no-referrer" src={image.url} alt="kamera"/>
                                                </div>
                                            </CarouselItem>
                                        )}
                                    </Carousel>
                                    <Lightbox
                                        open={openModal}
                                        index={imageIndex}
                                        close={() => setOpenModal(false)}
                                        slides={modalSlides}
                                        plugins={[Thumbnails, Zoom]}
                                        zoom={{
                                            maxZoomPixelRatio: 10,
                                            scrollToZoom: true
                                        }}
                                    />
                                </div>
                            </div>
                            {/*<div className="modal-footer">
                                <div className="modal-buttons-wrapper"> 
                                    <button className="user-top-panel-button" onClick={() => closePhotoView()}>Zamknij</button>
                                </div>
                            </div>*/}
                        </div>
                    }
                    {/* Podgląd ftp */}
                    {modal.ftp_info && !modal.error && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div id="modal-header" className="modal-header">
                                <button id="modal-close-button" onClick={() => closeModal()}>&#10006;</button>
                                <h2 className="modal-title">Pobieranie z FTP</h2>
                            </div>
                            <div className="modal-body">
                                <div id="ftp-info-container">
                                    <ol>
                                        <li>Pobierz, a następnie zainstaluj darmowego klienta FTP - Filezilla z <a className="ftp-link" target="_blank" rel="noreferrer" href="https://filezilla-project.org/download.php">tego adresu</a></li>
                                        <li>Połącz się z serwerem za pomocą poniższych danych:
                                            <ul className="ftp-nested-list">
                                                <li>Host - <span id="ftp_host" className="ftp-span">rubika.myftp.org</span><button className="ftp-button" onClick={() => copyText('ftp_host')}>{buttonStatus.ftp_host ? 'skopiowano' : 'kopiuj'}</button></li>
                                                <li>Użytkownik - <span id="ftp_user" className="ftp-span">filmy</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button className="ftp-button" onClick={() => copyText('ftp_user')}>{buttonStatus.ftp_user ? 'skopiowano' : 'kopiuj'}</button></li>
                                                <li>Hasło - <span id="ftp_password" className="ftp-span">997analiza</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button className="ftp-button" onClick={() => copyText('ftp_password')}>{buttonStatus.ftp_password ? 'skopiowano' : 'kopiuj'}</button></li>
                                            </ul>
                                        </li>
                                        <li>Filmy z badanego punktu znajdziesz w poniższych lokalizacjach:
                                            <ul className="ftp-nested-list">
                                                {ftpList.map((ftp, index) => (
                                                    <li key={index}>{ftp.disk}/{ftp.path}</li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>Jeśli potrzebujesz informacji jak korzystać z klienta FTP <a className="ftp-link" target="_blank" rel="noreferrer" href="https://analiza.badaniaruchu.pl/photos/ftp.jpg">zajrzyj tutaj</a></li>
                                    </ol>
                                </div>
                            </div>
                            {/*<div className="modal-footer">
                                <div className="modal-buttons-wrapper"> 
                                    <button className="user-top-panel-button" onClick={() => closePhotoView()}>Zamknij</button>
                                </div>
                            </div>*/}
                        </div>
                    }
                    {/* Informacje */}
                    {modal.info && !modal.error && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div className="modal-header">
                                <h2 className="modal-title">Informacja</h2>
                            </div>
                            <div className="modal-body">
                                {formErrors.length > 0 && 
                                    <div className="modal-info-wrapper">
                                        <p className="modal-info-text">Przed zakończeniem pomiaru należy uzupełnić brakujące informacje:</p>
                                        <ul className="modal-error-ul">
                                            {formErrors.map((error, index) => (
                                                <li className="modal-error-list" key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                                {(tempTrainToActivate || tempStationToActivate) && 
                                    <div className="modal-info-wrapper">
                                        <p className="modal-info-text">Czy na pewno chcesz zmienić {tempTrainToActivate ? 'wybrany pociąg' : 'wybraną stację'}?<br></br>Spowoduje to usunięcie bieżących pomiarów.</p>
                                    </div>
                                }
                                {modalMessage && 
                                    <div className="modal-info-wrapper">
                                        <p className="modal-info-text">{modalMessage}</p>
                                    </div>
                                }
                                {reactivationMessage && 
                                    <div className="modal-info-wrapper">
                                        <p className="modal-info-text">{reactivationMessage}</p>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer">
                                <div className="modal-buttons-wrapper"> 
                                    {reactivationMessage && <button className="user-top-panel-button" onClick={() => resumeWork()}>Wznów pracę</button>}
                                    {modalMessage && <button className="user-top-panel-button" onClick={() => closeModal()}>OK</button>}
                                    {formErrors.length > 0 && <button className="user-top-panel-button user-top-panel-button-red" onClick={() => closeModal()}>Powróć</button>}
                                    {(tempTrainToActivate || tempStationToActivate) && <button className="user-top-panel-button user-top-panel-button-red" onClick={() => confirmStageChange()}>Zmień</button>}
                                    {(tempTrainToActivate || tempStationToActivate) && <button className="user-top-panel-button" onClick={() => closeModal()}>Anuluj</button>}
                                </div>
                            </div>
                        </div>
                    }
                    {/* Aktualizacja czasu */}
                    {modal.time && !modal.error && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div className="modal-header">
                                <h2 className="modal-title">Raportuj czas pracy</h2>
                            </div>
                            <div className="modal-body">
                                <p className="modal-time-info-text">Jeśli czas obliczony w aplikacji różni się rzeczywistego czasu poświęconego na wykonanie danego zadania podaj go tutaj.</p>
                                <div className="modal-time-inputs-wrapper">
                                    <span className="modal-time-input-span">godzin</span>
                                    <input 
                                        className="input-number" 
                                        type="number" 
                                        onChange={(e) =>reportedTimeChange(e)}
                                        id={'reported_hours'}
                                        name={'reported_hours'}
                                        value={customTime.reported_hours}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                    <input 
                                        className="input-number" 
                                        type="number" 
                                        onChange={(e) =>reportedTimeChange(e)}
                                        id={'reported_minutes'}
                                        name={'reported_minutes'}
                                        value={customTime.reported_minutes}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                    <span className="modal-time-input-span">minut</span>
                                </div>
                                
                            </div>

                            <div className="modal-footer">
                                <div className="modal-buttons-wrapper"> 
                                    <button className={(customTime.reported_hours !== '' || customTime.reported_minutes !== '') ? "user-top-panel-button" : "user-top-panel-button user-top-panel-button-disabled"} onClick={() => reportTime()}>Akceptuj</button>
                                    <button className="user-top-panel-button user-top-panel-button-red" onClick={() => cancelTimeReport()}>Anuluj</button>
                                </div>
                            </div>
                            
                        </div>
                    }
                    {/* Błędy */}
                    {modal.error && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div className="modal-header modal-header-error">
                                <h2 className="modal-title">Błąd</h2>
                            </div>
                            <div className="modal-body">
                                {modalErrorMessage && 
                                    <div className="modal-info-wrapper">
                                        <p className="modal-info-text">{modalErrorMessage}</p>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer">
                                <div className="modal-buttons-wrapper"> 
                                    {modalErrorMessage && <button className="user-top-panel-button user-top-panel-button-red" onClick={() => handleModalClose()}>OK</button>}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
};