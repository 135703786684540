import React, { useState, useContext, /* useEffect */ } from "react";
import {UserContext} from './UserContext';
//import ErrorIcon from '../images/error2.png';

export default function Login(){

    const {loginUser, loggedInCheck} = useContext(UserContext);

    const [message, setMessage] = useState(false);

    const [intro, setIntro] = useState(true);

    const [formData, setFormData] = useState(
        {
            username: '',
            password: ''
        }
    );

    function formChange(event){

        const {name, value} = event.target;

        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });

    }

    const sendLogin = async(event) => {

        setMessage('');

        event.preventDefault();

        if(!formData.username || !formData.password){

            setMessage('Proszę wypełnić oba pola');

        } else {

            const data = await loginUser(formData);
            setMessage(data.message);
            await loggedInCheck();
            return;

        }
    }

    /*

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {

        const handleOnline = () => setIsOnline(true);

        const handleOffline = () => setIsOnline(false);
    
        window.addEventListener('online', handleOnline);

        window.addEventListener('offline', handleOffline);
    
        return () => {

            window.removeEventListener('online', handleOnline);

            window.removeEventListener('offline', handleOffline);
            
        };

    }, []);

    */

    return (
        <>
            {//isOnline && 
                <>
                    {intro && <div id="intro-outer-container">
                        <div id="intro-inner-container">
                            <button id="primary-login" onClick={() => setIntro(false)}>Logowanie</button>
                        </div>
                    </div>}
                    {!intro &&
                        <div id="login-outer-container">
                            <div id="login-inner-container">
                                <div id="login-inner-1">
                                    <div id="login-inner-2">
                                        <div id="login">
                                            <div className="login-field">
                                                <input 
                                                    className="login-form"
                                                    id="username"
                                                    type="text"
                                                    onChange={formChange}
                                                    name="username"
                                                    value={formData.username}
                                                    placeholder="Nazwa użytkownika"
                                                />
                                            </div>
                                            <div className="login-field">
                                                <input 
                                                    className="login-form"
                                                    id="password"
                                                    type="password"
                                                    onChange={formChange}
                                                    name="password"
                                                    value={formData.password}
                                                    placeholder="Hasło"
                                                />
                                            </div>
                                            <p id="login-button-wrapper">
                                                <button id="send-login" onClick={sendLogin}><span id="send-login-text">Zaloguj się</span></button>	
                                                {message && <span id="login-message">{message}</span>}
                                            </p>
                                        </div>
                                    </div>
                                    <div id="login-background">
                                        <span id="login-background-shape4" className="login-background-shape"></span>
                                        <span id="login-background-shape3" className="login-background-shape"></span>		
                                        <span id="login-background-shape2" className="login-background-shape"></span>
                                        <span id="login-background-shape1" className="login-background-shape"></span>
                                    </div>		
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
            {/*!isOnline && 
                <div id="error-outer-container">
                    <div id="error-inner-container">
                        <p className="text-error">Wygląda na to, że nie masz połączenia z internetem.</p>
                        <img
                            src={ErrorIcon}
                            alt='error'
                            className="error-image"
                        />
                        <p className="text-error">Przywróć połączenie aby pracować dalej.</p>
                    </div>
                </div>
            */}
        </>
    );
};