import React, { useState, useEffect } from "react";
import { Axios } from './UserContext';
import {ReactComponent as Gear} from '../images/gear.svg';
import { db } from "./Db";

export default function Schedules(){

    // Wyszukiwanie

    const [searching, setSearching] = useState(true);

    // Pociągi

    const [trains, setTrains] = useState([]);

    // Stacje

    const [stations, setStations] = useState([]);

    // Numery pociągów

    const [trainNumbers, setTrainNumbers] = useState([]);

    // Opóźnienia

    const [delays, setDelays] = useState([]);

    // Nagrania

    const [recordings, setRecordings] = useState([]);

    // Pobieranie danych

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getData(){

        let request_type = 'get train data';

        setSearching(true);

        Axios.post('classes/data.php', { request_type }, { timeout: 10000 }).then(function(response){
            if(typeof response.data === 'object' || Array.isArray(response.data)){
                if(Array.isArray(response.data.trains)){
                    setTrains(response.data.trains)
                }
                if(Array.isArray(response.data.stations)){
                    setStations(response.data.stations);
                }
                if(Array.isArray(response.data.delays)){
                    setDelays(response.data.delays);
                }
                if(Array.isArray(response.data.train_numbers)){
                    setTrainNumbers(response.data.train_numbers);
                }
                if(Array.isArray(response.data.recordings)){
                    setRecordings(response.data.recordings);
                }
            } else {
                setModal({...modal, show: true, info: true});
                setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z koordynatorem projektu.');
            }
            setSearching(false);
        }).catch((error) => {
            console.warn(error);
            setSearching(false);
            setModal({...modal, show: true, info: true});
            setModalMessage('Wystąpił błąd w trakcie pobierania danych. Odśwież stronę, a jeżeli problem będzie się powtarzał skontaktuj się z koordynatorem projektu.');
        });

    } 

    // Modale

    const [modalMessage, setModalMessage] = useState(null);

    const [modal, setModal] = useState({
        show: false, 
        info: false
    });

    function closeModal(){

        setModal({
            show: false, 
            info: false
        });

    }

    // Uzupełnianie pociągów o nazwy stacji i numer PKP

    const [updatedTrains, setUpdatedTrains] = useState([]);

    useEffect(() => {

        if(trains.length > 0 && stations.length > 0 && trainNumbers.length > 0){ 

            const updated = [...trains];

            updated.forEach(train => {

                const train_search = trainNumbers.find(u => u.train_id === train.train_id);

                if(train_search){

                    train.train_number = train_search.train_number;

                    const first_station = stations.find(u => u.station_id === train_search.first_station);

                    const last_station = stations.find(u => u.station_id === train_search.last_station);

                    if(first_station){

                        train.first_station_name = first_station.name;

                    }

                    if(last_station){

                        train.last_station_name = last_station.name;

                    }

                }

            });

            setUpdatedTrains(updated);

        }
        
    }, [trains, stations, trainNumbers]);

    const [recordedStations, setRecordedStations] = useState([]);

    useEffect(() => {

        if(stations.length > 0 && recordings.length > 0){ 

            const updated = [...recordings];

            updated.forEach(station => {

                const station_find = stations.find(u => u.station_id === station.station_id);

                if(station_find){

                    station.name = station_find.name;

                }

            });

            const sorted = updated.sort((a, b) => a.name.localeCompare(b.name));

            setRecordedStations(sorted);

        }
        
    }, [stations, recordings]);

    const [currentStation, setCurrentStation] = useState('');

    function onStationChange(e) {

        const value = e.target.value;

        setCurrentStation(value);

    }

    const [transfered, setTransfered] = useState(null);

    useEffect(() => {

        db.station.toArray().then(function(result){

            if(result.length > 0){

                let station = result[0].station;

                setTransfered(station);

                db.station.clear();

            }

        });

    }, []);

    useEffect(() => {

        if(recordedStations.length > 0 && transfered){

            setCurrentStation(transfered);

        }

    }, [transfered, recordedStations]);

    const [filteredTrains, setFilteredTrains] = useState([]);

    useEffect(() => {

        if(currentStation !== '' && updatedTrains.length > 0 && delays.length > 0){

            const match_trains = updatedTrains.filter(obj => {
                return Object.values(obj).some(value => value === currentStation);
            });


            match_trains.forEach(train => {

                const found = Object.keys(train).find(key => train[key] === currentStation);

                const index = found.substring(found.indexOf("_") + 1);

                train.station_index = index;

            });

            const sorted_trains = match_trains.sort((a, b) => {
    
                const stationIndexA = a.station_index;
                const stationIndexB = b.station_index;
    
                const departureA = a["departure_hour_" + stationIndexA];
                const departureB = b["departure_hour_" + stationIndexB];
                const arrivalA = a["arrival_hour_" + stationIndexA];
                const arrivalB = b["arrival_hour_" + stationIndexB];
    
                const timeA = departureA || arrivalA;

                const timeB = departureB || arrivalB
            
                const dateA = new Date(`1970-01-01 ${timeA}`);

                const dateB = new Date(`1970-01-01 ${timeB}`);
            
                return dateA - dateB;

            });

            setFilteredTrains(sorted_trains);

        }

        if(currentStation === ''){

            setFilteredTrains([]);

        }

    }, [currentStation, updatedTrains, delays]);


    return (
        <div id="schedule-app-outer-container">
            {updatedTrains.length === 0 && 
                <div className="waiting-wrapper">
                    {searching && 
                    <>
                        <p className="waiting-message">Proszę czekać - trwa pobieranie danych</p>
                        <Gear/>
                    </>}
                    {!searching && 
                    <>
                        <p className="waiting-message">Brak dostępnych zadań</p>
                        <button className='user-top-panel-button' onClick={()=>getData()}>Sprawdź ponownie</button>
                    </>}                    
                </div>
            }

            {updatedTrains.length > 0 && 
                <div className="schedule-outer-wrapper">
                    {recordedStations.length > 0 &&
                        <div className="job-selection-filter-wrapper">
                            <p className="job-selection-filter-label">Nazwa stacji</p>
                            <select
                                onChange={onStationChange}
                                value={currentStation}
                                name='job_station_name'
                                className="filter-select"
                                id="user-job-station"
                            >
                                <option value=''>Wybierz</option>
                                {recordedStations.map((station, index) => (
                                <option key={index} value={station.station_id}>{station.name}</option>
                                ))}
                            </select>
                        </div>
                    }               
                </div>
            }
            {filteredTrains.length > 0 && 
                <div className="schedule-job-working-middle-outer-wrapper">
                    <div className="schedule-job-working-table-wrapper">
                        <table className="schedule-job-working-details-table">
                            <thead>
                                <tr>
                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Pociąg</span></th>
                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Relacja</span></th>
                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Peron</span></th>
                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Tor</span></th>
                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Przyjazd</span></th>
                                    <th><div className="job-working-details-table-header-cell"></div><span className="job-working-details-table-span">Odjazd</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTrains.map((train, index) =>
                                    <tr key={index}>
                                        <td>{train.train_number}</td>
                                        <td>{train.first_station_name + " - " + train.last_station_name}</td>
                                        <td>{train['platform_number_'+train.station_index] ? train['platform_number_'+train.station_index] : "b/d"}</td>
                                        <td>{train['lane_number_'+train.station_index] ? train['lane_number_'+train.station_index] : "b/d"}</td>
                                        <td>{train['arrival_hour_'+train.station_index] ? train['arrival_hour_'+train.station_index] : "-"}</td>
                                        <td>{train['departure_hour_'+train.station_index] ? train['departure_hour_'+train.station_index] : "-"}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table> 
                    </div>
                </div>
            }
            {modal.show &&
                <div className="modal-overlay" onClick={() => closeModal()}>
                    {/* Informacje */}
                    {modal.info && 
                        <div className="modal" onClick={(e)=>e.stopPropagation()}>
                            <div className="modal-header">
                                <h2 className="modal-title">Informacja</h2>
                            </div>
                            <div className="modal-body">
                                {modalMessage && 
                                    <div className="modal-info-wrapper">
                                        <p className="modal-info-text">{modalMessage}</p>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer">
                                <div className="modal-buttons-wrapper"> 
                                    {modalMessage && <button className="user-top-panel-button" onClick={() => closeModal()}>OK</button>}
                                </div>
                            </div>
                        </div>
                    }  
                </div>
            }
        </div>
    );
};